import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Modal } from 'react-bootstrap'

// Components
import PageWrapper from '../PageWrapper'
import Loading from '../Loading'
import OldWagers from './OldWagers'

// Utils
import useWebSocket from '../../hooks/useWebSocket'
import utils from '../../utils/utils'
import API from '../../utils/api'
import './bto-events'

import './index.scss'

// Hooks
import useUserContext from '../../hooks/useUserContext'

const history = require('history').createBrowserHistory()

const Book = () => {
  const iframeRef = useRef()
  const { isLogged, updateUserBalance } = useUserContext()

  const searchParams = new URLSearchParams(window.location.search)

  const [isLoading, setIsLoading] = useState(true)
  const [showMyBets, setShowMyBets] = useState(false)
  const [source, setSource] = useState(null)

  useEffect(() => {
    let promise = isLogged ? API.launchSB() : API.launchSBDemo()

    promise.then((response) => {
      const { success, url } = response.data
      if (success) {
        setSource(url)
      }
      setIsLoading(false)
    })
  }, [isLogged])

  useEffect(() => {
    const showMyBets = searchParams.get('mybets') === '1'
    setShowMyBets(showMyBets)
  }, [searchParams])

  // websocket
  const websocketUrl = process.env.REACT_APP_PW_SOCKET_WAGERS
  const { readyState, webSocket } = useWebSocket(websocketUrl, {
    queryParams: { token: utils.getToken() }
  })

  useEffect(() => {
  
    const onMessage = data => {
      // messageHistory.current = messageHistory.current.concat(data);
      const { balance, currency } = data

      let userBalance = utils.getUserBalance()
      if (currency === 'real') {
        userBalance = { ...userBalance, balanceReal: balance }
        updateUserBalance(userBalance)
      }
    }
  
    if (readyState === 1 && utils.getUserInfo()) {
      webSocket.emit('subscribe', utils.getUserInfo().id)
      webSocket.on('wager:balance', onMessage)
    }
  }, [readyState])

  const closePanel = () => {
    setShowMyBets(false)
    history.push({ search: '' })
  }
  
  // Emitir evento de ruta inicial a BTO
  useEffect(() => {
    if (iframeRef.current) {
      btoEventBus.emit('navigateTo', { path: location.hash.replace('#', '')})
    }
  }, [iframeRef])

  // Cuando el usuario usa las flechas del navegador  ←  |  →
  // para moverse por su historial de rutas
  useEffect(() => {
    const unlisten = history.listen((location) => {
      btoEventBus.emit('navigateTo', { path: location.hash.replace('#', '')})
    })

    return unlisten
  }, [])

  useEffect(() => {
    let isFirtstEvent = false

    const handler = (ev) => {
      if(ev.data && ev.data.btoEvent && ev.data.type === 'routeChange') {
        if (!isFirtstEvent && ev.data.event?.to.path === '/') {
          isFirtstEvent = true
        } else {
          history.replace('/#' + ev.data.event?.to.path)
        }
      }
    }

    window.addEventListener('message', handler)

    return () => {
      window.removeEventListener('message', handler)
    }
  }, [])

  useEffect(() => {
    const onBtoMessageRecieved = (event) => {
      const { data, origin } = event
  
      console.log('received event', origin, data)
  
      if (origin !== 'https://sportsbook-launchoh.pariplaygames.com') return
  
      if (!data) {
        console.log('data is empty')
        return
      }
  
      const { btoEvent, type } = data
  
      switch (type) {
        case 'btoAppLoaded':
          if (window.btoEventBus) {
            console.log('sending event through btoEventBus')
            window.btoEventBus.emit('isNativeApp', false)
          } else {
            console.log('btoEventBus not available')
          }
  
          const message = {
            btoEvent: true,
            type: 'isNativeApp',
            event: false
          }
      
          console.log('btoAppLoaded sending message:', message)
          iframeRef.current.contentWindow.postMessage(message, '*')
        break
      }
  
    }

    window.addEventListener('message', onBtoMessageRecieved)

    return () => {
      window.removeEventListener('message', onBtoMessageRecieved)
    }
  }, [])

 

  const onLoadIframe = (event) => {
    console.log('🚀 ~ onLoadIframe ~ event:', event)

    if (!iframeRef.current) return

    iframeRef.current.contentWindow.focus()

    if (window.btoEventBus) {
      console.log('sending event through btoEventBus')
      btoEventBus.emit('isNativeApp', false)
    } else {
      console.log('btoEventBus not available')
    }

    const message = {
      btoEvent: true,
      type: 'isNativeApp',
      event: false
    }

    console.log('iframe.onLoad sending message:', message)
    iframeRef.current.contentWindow.postMessage(message, '*')
  }


  return (
    <PageWrapper>
      <div className='book-container'>
        {isLoading && <Loading />}
        <iframe
          ref={iframeRef}
          title='SportsBook'
          id='sb-iframe'
          width='100%'
          height='100%'
          frameBorder={0}
          src={source}
          onLoad={onLoadIframe}
          sandbox='allow-scripts allow-forms allow-same-origin allow-popups allow-modals cross-origin-isolated'
        />

        <Modal
          show={showMyBets}
          className='absoluteModal'
          dialogClassName='modal-dialog-xxxs'
        >
          <div>
            <div id='panelTitle' className='panel-abonar-titulo'>
              <span>Historial Apuestas Pasadas</span>
              <button
                className='cerrar-panel' onClick={closePanel}
              >
                <div className='btn-icon-close'>
                  <i className='ion-ios-close-empty' />
                </div>
              </button>
            </div>
          </div>
          <OldWagers />
        </Modal>
      </div>
    </PageWrapper>
  )
}

export default Book
