import React from 'react';
import {
  FormControl,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Modal,
} from 'react-bootstrap';
import { List, AutoSizer, InfiniteLoader } from 'react-virtualized';
import Tooltip from 'react-tooltip-portal';
import avatar from '../../assets/images/avatar-50.png';
import posiciones from '../../assets/images/posicionesIconBlanco-50.png';
import billete from '../../assets/images/cash1-50.png';
import pickcoins from '../../assets/images/pickcoin1-50.png';
import ticketIcon from '../../assets/images/silver_ticket_pick_empty.png';
import utils from '../../utils/utils';
import Utilities from '../../utils/quinielasUtils';
import API from '../../utils/api';
import numeral from 'numeral';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import _ from 'underscore';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from '../Loading';

import './TablaPosiciones.css'

const tipToolStyles = {
  color: '#000',
  background: '#fff',
  borderRadius: '3px',
  padding: '4px',
  fontSize: '85%',
  zIndex: '10000',
};

class ISRTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tipActive: false,
    };
    this.handleTip = (status) => {
      // console.log( 'HAndleTip', status);
      this.setState({ tipActive: status });
    };
  }

  render() {
    return (
      <div className="isrTooltip" style={{ color: 'white' }}>
        <i
          ref={(c) => (this.tipRef = c)}
          onMouseEnter={() => {
            this.handleTip(true);
          }}
          onMouseLeave={() => {
            this.handleTip(false);
          }}
          className="ion-help-circled"
          style={{fontSize: '14px'}}
        />
        <Tooltip
          active={this.state.tipActive}
          parent={this.tipRef}
          position="left"
          timeout="250"
          tipStyle={tipToolStyles}
        >
          <div>
            {' '}
            {`${this.props.availablePrize} - ${numeral(this.props.tax / 100).format(
              this.props.moneyFormat
            )} ISR `}{' '}
          </div>
        </Tooltip>
      </div>
    );
  }
}

/**
 * This component renders the number of strikes in the standings tableUpperSpace
 * @param currentUser   { Object }
 * @param maxStrikes    { Nummber }
 * @return Component
 */
export const Strikes = ({ currentUser, maxStrikes }) => {
  // console.log(currentUser.survivor_standing);
  let totalStrikes = 0;
  if (currentUser.survivor_standing) {
    totalStrikes = currentUser.survivor_standing.strikes;
    // console.log('HERE', totalStrikes )
  } else {
    totalStrikes = currentUser.strikes;
  }

  // if( !currentUser.strikes ){ return null }
  // let totalStrikes = Math.floor(Math.random() * Math.floor(4));
  // let totalStrikes = currentUser.hasOwnProperty('survivor_standing') && currentUser.survivor_standing.hasOwnProperty('strikes') ? currentUser.survivor_standing.strikes : currentUser.strikes ;

  let strikes = [ ... Array(maxStrikes <= 1 ? 1 : maxStrikes).keys()];
  let isDead = maxStrikes === currentUser.strikes;

  strikes = strikes.map((strike, strikeIdx) => {
    return (
      <i
        key={strike}
        className={` flex-col col-no-padding ${strikeIdx < totalStrikes ? 'isStrike' : ''}  ${
          strikeIdx < totalStrikes ? 'ion-record' : 'ion-ios-circle-outline'
          }`}
        style={{ textAlign: 'center' }}
      />
    );
  });

  // console.log( 'Strikes',totalStrikes );
  return (
    <div
      id="strikes"
      className={`flex-container justify align  ${isDead ? 'dead' : 'notDead'} `}
      style={{ marginLeft: '0px' }}
    >
      {strikes}
    </div>
  );
};

export const SurvivorContestStrikes = ({ standing, poolInfo }) => {
  let totalStrikes = 0; // standing.survivor_standing.strikes;
  let maxStrikes = 0; // standing.survivor_standing.strikes_available;

  if (poolInfo.sport_id !== 6) {
    maxStrikes = poolInfo && poolInfo.max_strikes ? poolInfo.max_strikes : 0;
    if (standing.survivor_standing) {
      totalStrikes = standing.survivor_standing.strikes;
    } else {
      totalStrikes = standing.strikes;
    }
  } else {
    if (standing.survivor_standing) {
      totalStrikes = standing.survivor_standing.strikes;
      maxStrikes = standing.survivor_standing.strikes_available;
    } else {
      totalStrikes = standing.strikes;
      maxStrikes = standing.strikes_available;
    }
  }

  let strikesCount = [...Array(maxStrikes).keys()];
  let isDead = maxStrikes === totalStrikes;

  const strikes = strikesCount.map((strike, strikeIdx) => {
    return (
      <i key={strike} className={`${strikeIdx < totalStrikes ? 'isStrike' : ''}  ${ strikeIdx < totalStrikes ? 'ion-record' : 'ion-ios-circle-outline' }`} style={{ textAlign: 'center' }} />
    );
  });

  return (
    <div
      id="strikes"
      className={`flex-container align  ${isDead ? 'dead' : 'notDead'} `}
      style={{ marginLeft: '0px' }}
    >
      {strikes}
    </div>
  );
};

/**
 * Yhis component render sthe confirmation PopUpConfirmationAddif
 * for the user trying to add a user as a friend
 * @param title             { String }
 * @param submitting        { boolean }
 * @param inviteStatus      { String }
 * @param show              { Boolean }
 * @param hideConfirmation  { Function }
 * @param label             { String }
 * @param userSelected      { Object }
 * @return Component
 */

export const PopUpConfirmationAdd = ({
  title,
  submitting,
  inviteStatus,
  show,
  hideConfirmation,
  label,
  handleInvite,
  userSelected,
}) => {
  if (!show) {
    return null;
  }
  let content = null;
  if (inviteStatus === 'error') {
    content = (
      <span className="flex-col col-80 col-offset-10">
        <div className="flex-container justify align column text-center">
          <i className="ion-alert-circled alert" />
        </div>
        <strong className="flex-row row-no-padding">
          <div className="flex-col col-center text-center">
            Sucedió un error al invitar a {userSelected ? userSelected.user.nick : 'tu amigo'}
          </div>
        </strong>
        <span className="flex-row row-no-padding">
          <div className="flex-col col-center text-center">¿Quieres intentarlo de nuevo?</div>
        </span>
      </span>
    );
  } else if (inviteStatus === 'success') {
    content = (
      <span className="flex-col col-80 col-offset-10 col-center col-no-padding text-center ">
        <div className="flex-container justify align column text-center">
          <i className="ion-checkmark-circled success" />
        </div>
        <strong className="flex-row row-no-padding">
          <div className="flex-col col-center">
            Agregaste a {userSelected ? userSelected.user.nick : 'tu amigo'}.
          </div>
        </strong>
      </span>
    );
  } else {
    content = (
      <span className="flex-col col-80 col-offset-10 col-center col-no-padding text-center">
        <div className="flex-container justify align column text-center">
          <i className="ion-person-add big-icon" />
        </div>
        <strong className="flex-row row-no-padding text-center">
          <div className="flex-col col-center  text-center ">
            ¿Quieres agregar a {userSelected ? userSelected.user.nick : 'pickwinero'} como tu amigo?
          </div>
        </strong>
      </span>
    );
  }

  return (
    <Modal bsSize="sm" dialogClassName="confirmationPanelPopUp" show={show} keyboard={false}>
      <div className="panel-abonar">
        <div
          id="panelTitle"
          className={`flex-row row-no-padding panel-abonar-titulo ${
            inviteStatus === 'error' ? 'failed' : 'Error'
            }`}
        >
          <span>{inviteStatus === 'error' ? 'No se pudo enviar tu solicitud' : title}</span>
          <button className="cerrar-panel" onClick={hideConfirmation}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="flex-row pickwin-panel" style={{ margin: '10px 0px' }}>
          {content}
        </div>
        <div className="flex-row row-center">
          <div className="flex-col col-90 col-offset-5">
            <div className="flex-row">
              <Button
                className={`flex-col col-center btn-rojo btn-submit text-center ${
                  inviteStatus === 'success' ? 'col-80 col-offset-10' : 'col-40 col-offset-5'
                  }`}
                onClick={() => {
                  hideConfirmation();
                }}
              >
                {inviteStatus === 'success' ? 'Cerrar' : 'No'}
              </Button>
              <Button
                disabled={submitting}
                className={` ${
                  inviteStatus === 'success' ? 'hide' : ''
                  } flex-col col-40 col-offset-5 col-center btn-pick btn-submit text-center`}
                onClick={() => {
                  handleInvite(userSelected.user_id);
                  // console.log('click');
                }}
              >
                {submitting ? (
                  <div className="flex-container justify align column">
                    <i className="ion-load-a loading small"></i>
                  </div>
                ) : (
                    'Si'
                  )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

/**
 * This component handles the button follow friend actions and renders
 * @param followSuccess { Boolean }
 * @param followUser    { Function }
 * @param followError   { Boolean }
 * @param user          { Object }
 * @return Component
 *
 */
export const ButtonFollowFriend = ({ followSuccess, followError, openAddConfirmation, user }) => {
  if (!user || !utils.isLogged()) {
    return null;
  }

  const uid = utils.getUserInfo().id;

  if (!utils.isLogged() || user.friend !== 0 || user.user_id === uid) {
    return <div className="posiciones-category friend quina flex-container align justify"></div>;
  }
  let conditionalComponent = null;
  if (!followSuccess && !followError) {
    conditionalComponent = (
      <div className="posiciones-category friend quina flex-container align justify">
        <span
          className={` flex-row row-center btn-addFriend row-no-padding`}
          onClick={() => {
            openAddConfirmation(user);
          }}
        >
          <i className="ion-plus-round flex-col col-center"></i>
        </span>
      </div>
    );
  } else if (followSuccess) {
    conditionalComponent = (
      <div className="flex-container align justify friend quina position-category successFollow">
        <i className="ion-checkmark-round " style={{ color: '#78bc28' }}></i>
      </div>
    );
  } else if (followError) {
    conditionalComponent = (
      <div className="flex-container align justify friend quina position-category errorFollow">
        <i className="ion-close-round " style={{ color: '#e00034' }}></i>
      </div>
    );
  }
  return conditionalComponent;
};

/**
 * This component renders the week controls weeklyButtons
 * @param poolWeeks     { Array }
 * @param currentWeek   { Object }
 * @param changeWeek    { Function }
 * @return Component
 */
const WeekControls = ({ poolWeeks, currentWeek, changeWeek }) => {
  // console.log(poolWeeks, currentWeek, changeWeek);
  let weeklyButtons = null;

  if (!poolWeeks && poolWeeks !== null) {
    weeklyButtons = <span>No hay semanas disponibles</span>;
  } else {
    weeklyButtons = poolWeeks.weeks.map((week) => {
      return <ToggleButton value={week.number}>{week.name}</ToggleButton>;
    });
  }

  return (
    <ToggleButtonGroup type="radio" value={currentWeek} onChange={changeWeek}>
      {weeklyButtons}
    </ToggleButtonGroup>
  );
};

/**
 * This component renders the Prize of the rowRenderer
 *
 * @param {getAganar} - function - this function calcullate the winnig qnty
 * @param {polInfo} - Object - this parameter represent all the info of the game
 * @param {userData} -Object - this parameter represent hte USer data
 * @return Component
 */
const Aganar = ({ poolInfo, userData, getAganar }) => {
  let moneyFormat = null;
  let currencyImage = null;
  let acronymPrize = null;
  let availablePrize = null;

  var stylesFormat = { position: 'relative', justifyContent: 'flex-end', paddingRight: '4px' };

  switch (poolInfo.prize_currency) {
    case 'ticket':
      moneyFormat = '0';
      acronymPrize = '';
      currencyImage = ticketIcon;
      if (poolInfo.status === 'upcoming') {
        currencyImage = null;
        availablePrize = '-';
      } else {
        availablePrize =
          !userData.user_prize || userData.user_prize.description === null
            ? '-'
            : userData.user_prize.description;
      }
      // currencyImage = ticketsIcon;
      break;
    case 'real':
      moneyFormat = '$0,000.00';
      acronymPrize = 'MX';
      availablePrize = !userData.user_prize
        ? numeral(getAganar(userData.standing.rank) / 100).format(moneyFormat)
        : numeral(userData.user_prize.amount / 100).format(moneyFormat);
      // currencyImage = billete;
      // console.log(availablePrize, userData);
      break;
    case 'pickcoin':
      acronymPrize = 'PC';
      moneyFormat = '0,000';
      availablePrize = !userData.user_prize
        ? numeral(getAganar(userData.standing.rank) / 100).format(moneyFormat)
        : numeral(userData.user_prize.amount / 100).format(moneyFormat);
      // currencyImage = pickcoin1;
      break;
    default:
  }

  if (poolInfo.status === 'closed' && userData.user_prize && userData.user_prize.base_amount) {
    return (
      <div className={` flex-row ${poolInfo.prize_currency === 'ticket' ? '' : ''} row-no-padding`}>
        <div className="flex-container justify align" style={{ ...stylesFormat }}>
          <ISRTooltip
            tax={userData.user_prize.tax}
            availablePrize={numeral(userData.user_prize.base_amount / 100).format(moneyFormat)}
            moneyFormat={moneyFormat}
          />
          {poolInfo.prize_currency === 'ticket' &&
            userData.user_prize !== null &&
            userData.user_prize.description !== null ? (
              <img src={currencyImage} className="pool-icons ticket" />
            ) : null}
          <small
            className={`cant${poolInfo.prize_currency === ' ticket' ? ' text-center small' : ''}`}
          >
            {' '}
            {availablePrize}{' '}
          </small>
          <small style={{ display: 'flex', alignItems: 'center' }}>{acronymPrize}</small>
        </div>
      </div>
    );
  } else {
    return (
      <div className={` flex-col ${poolInfo.prize_currency === 'ticket' ? '' : ''} col-no-padding`}>
        <div className="flex-container justify align" style={{ ...stylesFormat }}>
          {poolInfo.prize_currency === 'ticket' &&
            userData.user_prize !== null &&
            userData.user_prize.description !== null ? (
              <img src={currencyImage} className="pool-icons ticket" />
            ) : null}
          <small
            className={`cant ${poolInfo.prize_currency === 'ticket' ? 'text-center small' : ''}  `}
            style={{ fontSize: '0.9em' }}
          >
            {' '}
            {availablePrize}{' '}
          </small>
          <small style={{ display: 'flex', alignItems: 'center' }}>{acronymPrize}</small>
        </div>
      </div>
    );
  }
};

const AganarBracket = ({ poolInfo, userData, getAganar, tiesDistribution, premios, calculateTiesPrizes }) => {
  let moneyFormat = null;
  let currencyImage = null;
  let acronymPrize = null;
  let availablePrize = null;

  var stylesFormat = { position: 'relative', justifyContent: 'flex-end', paddingRight: '4px' };

  switch (poolInfo.prize_currency) {
    case 'ticket':
      moneyFormat = '0';
      acronymPrize = '';
      currencyImage = ticketIcon;
      if (poolInfo.status === 'upcoming') {
        currencyImage = null;
        availablePrize = '-';
      } else {
        availablePrize =
          !userData.user_prize || userData.user_prize.description === null
            ? '-'
            : userData.user_prize.description;
      }
      // currencyImage = ticketsIcon;
      break;
    case 'real':
      moneyFormat = '$0,000.00';
      acronymPrize = 'MX';
      if (poolInfo.status === "upcoming") {
        availablePrize = !userData.user_prize
          ? numeral(getAganar(userData.bracket_standing.rank) / 100).format(moneyFormat)
          : numeral(userData.user_prize.amount / 100).format(moneyFormat);
      } else {
        let ties = calculateTiesPrizes(poolInfo.available, premios, tiesDistribution)
        let rank = !userData.user_prize ? userData.bracket_standing.rank : userData.rank;
        let prize = ties.find(tie => tie.rank === rank);
        if (prize && prize.amount) {
          availablePrize = numeral(prize.amount / 100).format(moneyFormat);
        } else {
          availablePrize = numeral(0).format(moneyFormat);
        }
      }
      break;
    case 'pickcoin':
      acronymPrize = 'PC';
      moneyFormat = '0,000';
      availablePrize = !userData.user_prize
        ? numeral(getAganar(userData.bracket_standing.rank) / 100).format(moneyFormat)
        : numeral(userData.user_prize.amount / 100).format(moneyFormat);
      // currencyImage = pickcoin1;
      break;
    default:
  }

  if (poolInfo.status === 'closed' && userData.user_prize && userData.user_prize.base_amount) {
    return (
      <div className={` flex-row ${poolInfo.prize_currency === 'ticket' ? '' : ''} row-no-padding`}>
        <div className="flex-container justify align" style={{ ...stylesFormat }}>
          <ISRTooltip
            tax={userData.user_prize.tax}
            availablePrize={numeral(userData.user_prize.base_amount / 100).format(moneyFormat)}
            moneyFormat={moneyFormat}
          />
          {poolInfo.prize_currency === 'ticket' &&
            userData.user_prize !== null &&
            userData.user_prize.description !== null ? (
              <img src={currencyImage} className="pool-icons ticket" />
            ) : null}
          <small
            className={`cant ${poolInfo.prize_currency === 'ticket' ? 'text-center small' : ''}  `}
          >
            {' '}
            {availablePrize}{' '}
          </small>
          <small style={{ display: 'flex', alignItems: 'center' }}>{acronymPrize}</small>
        </div>
      </div>
    );
  } else {
    return (
      <div className={` flex-col ${poolInfo.prize_currency === 'ticket' ? '' : ''} col-no-padding`}>
        <div className="flex-container justify align" style={{ ...stylesFormat }}>
          {poolInfo.prize_currency === 'ticket' &&
            userData.user_prize !== null &&
            userData.user_prize.description !== null ? (
              <img src={currencyImage} className="pool-icons ticket" />
            ) : null}
          <small
            className={`cant ${poolInfo.prize_currency === 'ticket' ? 'text-center small' : ''}  `}
          >
            {' '}
            {availablePrize}{' '}
          </small>
          <small style={{ display: 'flex', alignItems: 'center' }}>{acronymPrize}</small>
        </div>
      </div>
    );
  }
};

/**
 *This component renders the Prize of the rowSurvivorRenderer
 * @param {getAganar} - function - this function calcullate the winnig qnty
 * @param {polInfo} - Object - this parameter represent all the info of the game
 * @param {userData} -Object - this parameter represent hte USer data
 * @return Componet
 */
const AganarSurvivor = ({
  gameInfo,
  userData,
  getAganar,
  prizesList,
  tiesDistribution,
  standings,
}) => {
  let moneyFormat = null;
  let currencyImage = null;
  let acronymPrize = null;
  let availablePrize = null;
  // console.log('Agnar survivor', gameInfo , userData , prizesList, tiesDistribution, standings, getAganar);

  switch (gameInfo.prize_currency) {
    case 'ticket':
      moneyFormat = '0';
      acronymPrize = '';
      currencyImage = ticketIcon;
      if (gameInfo.status === 'upcoming') {
        currencyImage = null;
        availablePrize = '-';
      } else {
        availablePrize =
          !userData.user_prize || userData.user_prize.description === null
            ? '-'
            : userData.user_prize.description;
      }
      // currencyImage = ticketsIcon;
      break;
    case 'real':
      _.forEach(tiesDistribution, (tie) => {
        if (tie.rank === userData.rank) {
          if (
            userData.user_prize &&
            userData.user_prize.amount === null
          ) {
            userData.user_prize.amount = tie.prize_amount;
          }
        }
      });


      moneyFormat = '$0,000.00';
      acronymPrize = 'MX';
      availablePrize = numeral(
        (userData.user_prize && userData.user_prize.amount ? userData.user_prize.amount : 0) / 100
      ).format(moneyFormat);
      break;
    case 'pickcoin':
      acronymPrize = 'PC';
      moneyFormat = '0,000';
      availablePrize = !userData.user_prize
        ? numeral(0).format(moneyFormat)
        : numeral(userData.user_prize.amount / 100).format(moneyFormat);
      // currencyImage = pickcoin1;
      break;
    default:
  }

  if (userData.user_prize && userData.user_prize.base_amount) {
    return (
      <div
        className={`posiciones-category a-ganar flex-col col-25 col-no-padding justify-end content-isrtooltip`}
        style={{ position: 'relative' }}
      >
        <ISRTooltip
          tax={userData.user_prize.tax}
          availablePrize={numeral(userData.user_prize.base_amount / 100).format(moneyFormat)}
          moneyFormat={moneyFormat}
        />
        <div className="flex-contaienr  column justify align">
          <div className="flex-container justify align">
            {gameInfo.prize_currency === 'ticket' &&
              userData.user_prize !== null &&
              userData.user_prize.description !== null ? (
                <img src={currencyImage} className="pool-icons" />
              ) : null}
            <span className={`cant`} style={{ fontSize: '85%' }}>
              {' '}
              {availablePrize}{' '}
            </span>
            <small style={{ display: 'flex', alignItems: 'center' }}>{acronymPrize}</small>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="posiciones-category a-ganar flex-col col-25 col-no-padding justify-end">
      <div className="flex-container end align">
        {gameInfo.prize_currency === 'ticket' &&
          userData.user_prize !== null &&
          userData.user_prize.description !== null ? (
            <img src={currencyImage} className="pool-icons" />
          ) : null}
        <span className="cant"> {availablePrize}</span>
        <small>{acronymPrize}</small>
      </div>
    </div>
  );
};

/**
 * this Component renders and handles the search of a nick in the table Standings
 * @param placeholder         { String }
 * @param value               { String }
 * @param fetchStandings      { Function }
 * @param changeSearchNick    { Function }
 * @param resetValue          { Function }
 * @param submitting          { Boolean }
 * @return Component
 */
export const SearchableInput = ({
  placeholder,
  value,
  fetchStandings,
  changeSearchNick,
  resetValue,
  submitting,
}) => {
  // console.log( 'Searchable' );
  return (
    <div className="flex-row row-no-padding row-center" id="search-field-nick">
      <div id="searchableNickInput" className="flex-col col-75 col-offset-5 col-no-padding">
        <FormControl
          onChange={(e) => {
            changeSearchNick(e);
            fetchStandings(e);
          }}
          placeholder={placeholder}
          type="text"
          value={value}
        />
      </div>
      <div className="flex-col col-no-padding col-15 btn-search-field">
        <Button
          onClick={(e) => {
            console.log('Clicked');
            resetValue();
          }}
          className="btn-default"
        >
          <i className={`${submitting ? 'ion-load-a loading' : 'ion-close-circled'}`}> </i>
        </Button>
      </div>
    </div>
  );
};

class TablaPosiciones extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props.posiciones)
    this.state = {
      mounted: false,
      usrData: null,
      usersItems: this.props.posiciones ? this.props.posiciones.items : [],
      remoteRowCount: 0,
      currentWeek: null,
      poolWeeks: null,
      errorFollow: false,
      successFollow: false,
      maxHeightMisQinielas: 360,
    };

    this.rowRenderer = this.rowRenderer.bind(this);
    this.rowBracketRenderer = this.rowBracketRenderer.bind(this);
    this.rowSurvivorRenderer = this.rowSurvivorRenderer.bind(this);
    this.rowSurvivorContestRenderer = this.rowSurvivorContestRenderer.bind(this);
    this.loadMoreRows = this.loadMoreRows.bind(this);
    this.isRowLoaded = this.isRowLoaded.bind(this);
    this.isRowLoaded = this.isRowLoaded.bind(this);

    this.handleListSize = (entry) => {
      // console.log( entry );
      let $searchThis = $(`#picksContainer-${entry}`);
      if ($searchThis.length === 0) {
        return null;
      }

      let myPanel = $searchThis.find('.panelMisQuinielas').height();
      let myPanelTitle = $searchThis.find('.title-quiniela.main').height();
      let myPanelTitleTabla = $searchThis.find('.position-list .title-quiniela').height();
      let myPanelButon = $searchThis.find('.titulo-mis-picks').height();
      let mySearchable = $searchThis.find('#search-field-nick').height();
      let myHeaderTabla = $searchThis.find('.position-list .position-header').height();

      let myHeight = parseInt(
        myPanel - (myPanelTitle + myPanelTitleTabla + myPanelButon + mySearchable + myHeaderTabla)
      );
      return myHeight < 360 ? 360 : myHeight - 28;
    };

    /**
     * This method renders the rowof a survivor with Strikes
     */
    this.rowSurvivorSoccerRenderer = (props) => {
      let content = null;
      const usersItems = this.props.posiciones.items;
      let currentUser = usersItems[props.index];
      //let itHasStrikes = currentUser.strikes;
      // console.log('Row Renderer Survivor',);
      //
      const followSuccess = !this.state.successFollow
        ? false
        : this.state.successFollow.find(
          (success) => success.status && success.id === usersItems[props.index].user_id
        );
      const followError = !this.state.errorFollow
        ? false
        : this.state.errorFollow.find(
          (error) => error.status && error.id === usersItems[props.index].user_id
        );

      // console.log('Row Renderer Pos', usersItems[3], props.index);
      if (usersItems === undefined || usersItems === null || usersItems.length === 0) {
        console.log('escape');
        return;
      }
      // let prizeFormat = this.props.premios.pool.entry_currency === 'real' ? '$0,000.00' : '0,000';
      // console.log( usersItems[props.index].survivor_entry_id, this.props.selectedEntry )
      content = (
        <div
          key={props.key}
          id={Utilities.highlightThisUser(usersItems[props.index].user_id, 64560)}
          className={` ${usersItems[props.index].alive ? 'gano' : 'perdio'} ${
            this.props.selectedEntry === usersItems[props.index].survivor_entry_id ? 'user' : ''
            } position-item survivor flex-row row-no-padding row-center
          `}
        >
          <ButtonFollowFriend
            followSuccess={followSuccess}
            followError={followError}
            openAddConfirmation={this.openAddConfirmation}
            user={usersItems[props.index]}
          />
          <div className="posiciones-category rank flex-col col-10 quina">
            <span className="picks-rank">{`${usersItems[props.index].rank}`}°</span>
          </div>
          <div className="posiciones-category flex-col col-30 jugador quina">
            <img
              src={
                usersItems[props.index].user.avatar === null
                  ? avatar
                  : `${usersItems[props.index].user.avatar_dir}${
                  usersItems[props.index].user.avatar
                  }`
              }
              className="img-circle score-avatars"
              style={{ height: '20px', width: '20px' }}
            />
            <span
              style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              title={`${usersItems[props.index].user.nick}`}
            >
              {`${usersItems[props.index].user.nick}`}
            </span>
            <sup>{`${
              usersItems[props.index].survivor_entry.number !== undefined && usersItems[props.index].survivor_entry.number !== null
                ? usersItems[props.index].survivor_entry.number
                : ''
              }`}</sup>
          </div>
          <div className="posiciones-category text-center flex-col col-15 col-no-padding strikes quina">
            <Strikes currentUser={currentUser} maxStrikes={this.props.poolInfo.max_strikes} />
          </div>
          {usersItems[props.index].elimination_week_id === null &&
            usersItems[props.index].alive &&
            this.props.poolInfo.status !== 'closed' ? (
              <div className="flex-col col-25"></div>
            ) : !usersItems[props.index].elimination_week_id &&
              !usersItems[props.index].alive &&
              this.props.poolInfo.status !== 'closed' ? (
                <div className="flex-col col-25 ">
                  <div className="flex-container justify-end align">PD</div>
                </div>
              ) : (
                <AganarSurvivor
                  gameInfo={this.props.poolInfo}
                  userData={usersItems[props.index]}
                  getAganar={this.props.getAganar}
                  prizesList={this.props.premios}
                  tiesDistribution={this.props.tiesDistribution}
                  standings={this.props.standings}
                />
              )}
          <div
            className={`posiciones-category flex-col alive col-15 quina ${
              usersItems[props.index].alive ? 'gano' : 'perdio'
              }`}
          >
            <i
              className={
                !usersItems[props.index].alive
                  ? 'flex-container justify align ion-close-round'
                  : 'flex-container justify align ion-checkmark-round'
              }
            />
          </div>
        </div>
      );

      return (
        <div
          className={`${props.index % 2 ? 'even-colored-row' : ''}`}
          key={props.key}
          style={props.style}
        >
          {content}
        </div>
      );
    };

    /**
     * this methods handle the add confirmation
     */
    this.openAddConfirmation = (users) => {
      this.setState({ showAddConfirmation: true, userSelected: users, inviteStatus: null });
    };

    this.hideAddConfirmation = () => {
      this.setState({ showAddConfirmation: false, userSelected: null, inviteStatus: null });
    };

    /**
     * This method sends the request to add a friend with a given id
     * @param id  { Number }
     * @return status
     */
    this.followUser = (id) => {
      console.log(' %c Follow User +  ', 'color:rgb(223, 75, 38) ; font-size:2em ;');
      console.log(id);
      API.followUser(id)
        .then((response) => {
          console.log(response);
          if (response.data.message === 'ok') {
            if (!this.state.successFollow) {
              this.setState(
                {
                  inviteStatus: 'success',
                },
                () => {
                  this.list.forceUpdateGrid();
                }
              );
            } else {
              // let temSuccessFollow = [...this.state.successFollow, {id, status:true} ];
              this.setState(
                {
                  inviteStatus: 'error',
                },
                () => {
                  this.list.forceUpdateGrid();
                }
              );
            }
          }
          // console.log(response);
        })
        .catch((e) => {
          console.log(e);
          // if( !this.state.errorFollow){
          this.setState(
            {
              inviteStatus: 'error',
            },
            () => {
              this.list.forceUpdateGrid();
            }
          );
          console.error(e);
          Sentry.captureException(e);
        });
    };

    /**
     * This method reset the value as an empty string
     * and deletes the value from the input
     */
    this.resetValue = () => {
      this.props.resetNickValue();
      $('#searchableNickInput .form-control').val('');
    };

    /**
     * this method handles the weekly to full standings view
     * @param value { Number }
     */
    this.changeWeek = (value) => {
      this.setState({
        submitting: true,
      });
      API.fetchWeeklyStandings(this.props.poolInfo.pool.id, value).then((response) => {
        this.setState({
          currentWeek: value,
          posicionesSemanales: response.data,
          submitting: false,
        });
      });
    };

    /**
     * this Method calculates the upperspace in the TableSection
     * to calculate the correct height of the container
     * @return the extra space of the whole table ( Headers ).
     */
    this.getUpperSpace = () => {
      return;
      // console.log('getupper')
      if (this.props.entryId !== null) {
        const positionListOverflow = $('.position-list .overflow');
        const listaPosiciones = $('.lista-posiciones');
        // console.log(positionListOverflow, listaPosiciones);
        if (positionListOverflow.length > 0 && listaPosiciones.length > 0) {
          // console.log('Resize Tabla');
          // console.log(positionListOverflow[0].offsetHeight, listaPosiciones[0]);
          listaPosiciones[0].style.height = positionListOverflow[0].offsetHeight - 25 + 'px';
        }
      } else {
        const positionListOverflow = $('.position-list .overflow');
        const listaPosiciones = $('.lista-posiciones');
        // console.log(positionListOverflow, listaPosiciones);
        if (positionListOverflow.length > 0 && listaPosiciones.length > 0) {
          // console.log('Resize Tabla');
          // console.log(positionListOverflow[0].offsetHeight, listaPosiciones[0]);
          listaPosiciones[0].style.height = positionListOverflow[0].offsetHeight + 'px';
        }
      }
    };
  }
  /**
   * This function render the rows of the Quiniela table
   * @param { Object } props
   *@return { Component }
   *
   */
  rowRenderer(props) {
    const usersItems = this.props.posiciones.items;
    const standing = usersItems[props.index];
    const { entry_id, user, points, total_possibly_correct, rank, user_id } = standing;
    let avatarImg = null

    if (user.avatar !== null) {
      avatarImg = <img src={`${user.avatar_dir}${user.avatar}`} className='img-circle score-avatars' alt='avatar' />
    } else {
      avatarImg = <img src={avatar} alt='avatar' className='img-circle score-avatars' />
    }

    if (usersItems === undefined || usersItems === null || usersItems.length === 0) {
      console.log('escape');
      return;
    }

    const followSuccess = !this.state.successFollow
      ? false
      : this.state.successFollow.find(
        (success) => success.status && success.id === user_id
      );
    const followError = !this.state.errorFollow
      ? false
      : this.state.errorFollow.find(
        (error) => error.status && error.id === user_id
      );

    let parlayDeadlines = this.props.parlayDeadlines;
    let d = new Date();
    let today = d.getTime();
    let activeBloks = 0;
    parlayDeadlines.map((e) => {
      let parlayDate = Date.parse(e);
      if (today > parlayDate) {
        activeBloks++;
      }
    })

    let parlayStyleAganar = "";
    if (this.props.poolInfo.pool.parlay_flag === true) {
      parlayStyleAganar = 'alive gano';
      if (standing.parlay_elimination !== null) {
        parlayStyleAganar = 'alive perdio';
      }
    }

    return (
      <div
        id={Utilities.highlightThisUser(user_id, 64560)}
        key={props.key}
        style={{
          ...props.style
        }}
        className={`flex-row no-padding row-center ${this.props.entryId === entry_id ? 'own-highlight' : ''} table-positions-row ${props.index % 2 ? 'even-colored-row' : ''}`}
      >
        <ButtonFollowFriend
          followSuccess={followSuccess}
          followError={followError}
          openAddConfirmation={this.openAddConfirmation}
          user={standing}
        />
        {this.props.poolInfo.pool.parlay_flag === null && (
          <div className="flex-col col-15 text-center text-bold">
            <span>{rank}°</span>
          </div>
        )}
        <div className="flex-col col-30 flex-container align">
          {avatarImg}
          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', minWidth: '0px' }} title={user.nick} >
            {user.nick}
          </span>{' '}
          <sup>{standing.entry.number !== undefined && standing.entry.number !== null ? standing.entry.number : ''}</sup>
        </div>
        <div className="flex-col col-15 text-center">
          {this.props.poolInfo.pool.parlay_flag === null && (
            <span>
              {this.props.poolInfo.pool.closed ? points : total_possibly_correct}{' '}
            </span>
          ) || (
            <span>{this.props.poolInfo.pool.closed ? points : total_possibly_correct} / {activeBloks}</span>
          )}
        </div>
        <div className={`flex-col col-30 ${parlayStyleAganar} text-bold`}>
          <Aganar
            poolInfo={this.props.poolInfo.pool}
            userData={standing}
            getAganar={this.props.getAganar}
          />
        </div>
      </div>
    );
  }

  /**
   * This function render the rows of the brackets table
   * @param { Object } props
   *@return { Component }
   *
   */
  rowBracketRenderer(props) {
    const usersItems = this.props.posiciones.items;
    const standing = usersItems[props.index];
    const { entry_id, user, points, possible_points, total_possibly_correct, rank, user_id } = standing;
    let avatarImg = null

    if (user.avatar !== null) {
      avatarImg = <img src={`${user.avatar_dir}${user.avatar}`} className='img-circle score-avatars' alt='avatar' />
    } else {
      avatarImg = <img src={avatar} alt='avatar' className='img-circle score-avatars' />
    }

    const followSuccess = !this.state.successFollow
      ? false
      : this.state.successFollow.find(
        (success) => success.status && success.id === user_id
      );
    const followError = !this.state.errorFollow
      ? false
      : this.state.errorFollow.find(
        (error) => error.status && error.id === user_id
      );

    if (usersItems === undefined || usersItems === null || usersItems.length === 0) {
      console.log('escape');
      return;
    }

    return (
      <div
        id={Utilities.highlightThisUser(user_id, 64560)}
        key={props.key}
        style={props.style}
        className={`flex-row no-padding row-center ${this.props.entryId === entry_id ? 'user' : ''} table-positions-row ${props.index % 2 ? 'even-colored-row' : ''}`}
      >
        <ButtonFollowFriend
          followSuccess={followSuccess}
          followError={followError}
          openAddConfirmation={this.openAddConfirmation}
          user={standing}
        />
        <div className="flex-col col-15 text-center text-bold">
          <span>{rank}°</span>
        </div>
        <div className="flex-col col-30 flex-container align">
          {avatarImg}
          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', minWidth: '0px', fontWeight: '400' }} title={user.nick} >
            {user.nick}
          </span>{' '}
          <sup>{standing.bracket_entry.number !== undefined && standing.bracket_entry.number !== null ? standing.bracket_entry.number : ''}</sup>
        </div>
        {this.props.poolInfo.type !== 'perfect' &&
        <div className="flex-col col-15 text-center">
          <span> {this.props.poolInfo.closed ? points : possible_points} </span>
        </div>
        }
        <div className={`flex-col ${this.props.poolInfo.type === 'perfect' ? 'col-20' : 'col-30'}`}>
          <AganarBracket
            poolInfo={this.props.poolInfo}
            userData={standing}
            getAganar={this.props.getAganar}
            tiesDistribution={this.props.tiesDistribution}
            premios={this.props.premios}
            calculateTiesPrizes={this.props.calculateTiesPrizes}
          />
        </div>
        {this.props.poolInfo.type === 'perfect' &&
          <div className={`posiciones-category alive flex-col col-10  quina ${usersItems[props.index].alive ? 'gano' : 'perdio'}`}>
            <i className={!usersItems[props.index].alive ? 'flex-container justify align ion-close-round' : 'flex-container justify align ion-checkmark-round'} />
          </div>
        }
      </div>
    );
  }

  /**
   * This function render the rows of the survivor table
   * @param { Object } props}
   *@return { Component }
   */
  rowSurvivorRenderer(props) {
    let content = null;
    const usersItems = this.props.posiciones.items;
    //let currentUser = usersItems[props.index];
    // console.log('Row Renderer Survivor',);

    const followSuccess = !this.state.successFollow
      ? false
      : this.state.successFollow.find(
        (success) => success.status && success.id === usersItems[props.index].user_id
      );
    const followError = !this.state.errorFollow
      ? false
      : this.state.errorFollow.find(
        (error) => error.status && error.id === usersItems[props.index].user_id
      );
    // console.log(followSuccess);
    // console.log(followError);
    // console.log('Row Renderer Pos', usersItems[3], props.index);
    if (usersItems === undefined || usersItems === null || usersItems.length === 0) {
      console.log('escape');
      return;
    }

    // let prizeFormat = this.props.premios.pool.entry_currency === 'real' ? '$0,000.00' : '0,000';
    content = (
      <div
        key={props.key}
        id={Utilities.highlightThisUser(usersItems[props.index].user_id, 64560)}
        className={` ${usersItems[props.index].alive ? 'gano' : 'perdio'} ${
          this.props.selectedEntry !== null &&
            this.props.selectedEntry === usersItems[props.index].survivor_entry_id
            ? 'user'
            : ''
          } position-item survivor flex-row row-no-padding row-center `}
      >
        <ButtonFollowFriend
          followSuccess={followSuccess}
          followError={followError}
          openAddConfirmation={this.openAddConfirmation}
          followUser={this.followUser}
          user={usersItems[props.index]}
        />
        <div className="posiciones-category rank flex-col col-15 quina">
          <span className="picks-rank">{`${usersItems[props.index].rank}`}°</span>
        </div>
        <div className="posiciones-category flex-col col-30 jugador quina">
          <img
            src={
              usersItems[props.index].user.avatar === null
                ? avatar
                : `${usersItems[props.index].user.avatar_dir}${usersItems[props.index].user.avatar}`
            }
            className="img-circle score-avatars"
            style={{ height: '20px', width: '20px' }}
          />
          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {usersItems[props.index].user.nick}
          </span>
          <sup>{`${
            usersItems[props.index].survivor_entry.number !== undefined && usersItems[props.index].survivor_entry.number !== null
              ? usersItems[props.index].survivor_entry.number
              : ''
            }`}</sup>
        </div>
        {usersItems[props.index].elimination_week_id === null &&
          usersItems[props.index].alive &&
          this.props.poolInfo.status !== 'closed' ? (
            <div className="flex-col col-25"></div>
          ) : !usersItems[props.index].elimination_week_id &&
            !usersItems[props.index].alive &&
            this.props.poolInfo.status !== 'closed' ? (
              <div className="flex-col col-25  ">
                <div className="flex-container justify-end align">PD</div>
              </div>
            ) : (
              <AganarSurvivor
                gameInfo={this.props.poolInfo}
                userData={usersItems[props.index]}
                getAganar={this.props.getAganar}
                prizesList={this.props.premios}
                tiesDistribution={this.props.tiesDistribution}
                standings={this.props.standings}
              />
            )}
        <div
          className={`posiciones-category flex-col alive col-15 quina ${
            usersItems[props.index].alive ? 'gano' : 'perdio'
            }`}
        >
          <i
            className={!usersItems[props.index].alive ? 'ion-close-round' : 'ion-checkmark-round'}
          />
        </div>
      </div>
    );

    return (
      <div
        className={`${props.index % 2 ? 'even-colored-row' : ''}`}
        key={props.key}
        style={props.style}
      >
        {content}
      </div>
    );
  }

  rowSurvivorContestRenderer(props) {
    const usersItems = this.props.posiciones.items;
    let currentUser = usersItems[props.index];
    const { entry_id, user, points, possible_points, total_possibly_correct, rank, user_id } = currentUser;

    let avatarImg = null
    if (currentUser.user.avatar !== null) {
      avatarImg = <img src={`${currentUser.user.avatar_dir}${currentUser.user.avatar}`} className='img-circle score-avatars' alt='avatar' />
    } else {
      avatarImg = <img src={avatar} alt='avatar' className='img-circle score-avatars' />
    }
    let followSuccess = false;
    let followError = false;

    return (
      <div
        key={props.key}
        style={props.style}
        id={Utilities.highlightThisUser(currentUser.user_id, 64560)}
        className={`${currentUser.alive ? 'gano' : 'perdio'} ${this.props.selectedEntry === currentUser.survivor_entry_id ? 'user' : ''} position-item survivor flex-row row-no-padding row-center table-positions-row ${props.index % 2 ? 'even-colored-row' : ''}`}
      >
        <ButtonFollowFriend
          followSuccess={followSuccess}
          followError={followError}
          openAddConfirmation={this.openAddConfirmation}
          user={currentUser}
        />
        <div className="flex-col col-10 text-center">
          <span>{rank}°</span>
        </div>

        <div className="flex-col col-30 flex-container align">
          {avatarImg}
          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', minWidth: '0px' }} title={user.nick} >
            {user.nick}
          </span>{' '}
          <sup>{currentUser.survivor_entry.number !== undefined && currentUser.survivor_entry.number !== null ? currentUser.survivor_entry.number : ''}</sup>
        </div>
        <div className="flex-col col-15 col-no-padding">
          <SurvivorContestStrikes standing={currentUser} poolInfo={this.props.poolInfo} />
        </div>

        {this.props.poolInfo.status !== 'upcoming' && currentUser.alive === false ? (
          <AganarSurvivor
            gameInfo={this.props.poolInfo}
            userData={currentUser}
            getAganar={this.props.getAganar}
            prizesList={this.props.premios}
            tiesDistribution={this.props.tiesDistribution}
            standings={this.props.standings}
          />
        ) : !currentUser.alive && currentUser.elimination_contest_id ? (
          <div className="flex-col col-25">
            <div className="flex-container justify-end align">PD</div>
          </div>
        ) : (
              <div className="flex-col col-25"></div>
            )}
        <div className={`posiciones-category flex-col alive col-15 quina ${currentUser.alive ? 'gano' : 'perdio'}`} >
          <i className={!currentUser.alive ? 'flex-container justify align ion-close-round' : 'flex-container justify align ion-checkmark-round'} />
        </div>
      </div>
    );
  }

  isRowLoaded({ index }) {
    // console.log(index);
    return this.props.posiciones.items && !!this.props.posiciones.items[index];
  }

  loadMoreRows({ startIndex, stopIndex }) {
    console.log('Load More...');
    this.props.siguientePagPositions();
    setTimeout(() => {
      $('.score-avatars')
        .height(22)
        .width(22);
    }, 700);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.liveFixturesData !== this.props.liveFixturesData) {
      // console.log('Standings Changed Updated');
      if (this.list !== undefined && this.list !== null) {
        this.list.forceUpdateGrid();
        setTimeout(() => {
          $('.score-avatars')
            .height(22)
            .width(22);
        }, 500);
      }
    }
    if (prevProps.sortChanged !== this.props.sortChanged) {
      // console.log('Sort Changed!');
      // this.list.forceUpdate();
      if (this.list) {
        setTimeout(() => {
          $('.score-avatars')
            .height(22)
            .width(22);
        }, 500);
        this.list.forceUpdateGrid();
      }
    }
    if (prevProps.cambiarEntradas !== this.props.cambiarEntradas) {
      // console.log('Cambiaron entradas forceUpdate');
      if (this.list) {
        this.list.forceUpdateGrid();
        setTimeout(() => {
          $('.score-avatars')
            .height(22)
            .width(22);
        }, 500);
      }
    } else if (
      prevProps.selectedEntry &&
      prevProps.selectedEntry.id !== this.props.selectedEntry.id
    ) {
      console.log('Cambiaron entradas forceUpdate');
      if (this.list.forceUpdateGrid) {
        this.list.forceUpdateGrid();
        setTimeout(() => {
          $('.score-avatars')
            .height(22)
            .width(22);
        }, 500);
      }
    }

    if (this.list !== undefined && this.list !== null) {
      this.list.forceUpdateGrid();
    }
  }

  componentDidMount() {
    this.setState(
      () => {
        return {
          mounted: true,
          userData: utils.getUserInfo(),
          logged: utils.isLogged(),
        };
      },
      () => {
        setTimeout(() => {
          this.getUpperSpace();
          if (this.props.type === 'survivor') {
            this.setState({
              maxHeightList: this.handleListSize(this.props.selectedEntry),
            });
          }
        }, 500);
      }
    );

  }

  render() {
    let listUser = this.props.entry;
    let poolInfo =
      this.props.poolInfo.pool !== undefined ? this.props.poolInfo.pool : this.props.poolInfo;
    let prizeCurrency =
      this.props.poolInfo.pool !== undefined
        ? this.props.poolInfo.pool.prize_currency
        : this.props.poolInfo.prize_currency;
    let rowUser = null;
    let conditionalComponent = null;
    listUser = this.props.entry;
    if (listUser !== null && listUser !== undefined &&  !listUser.hasOwnProperty('standing')) {
      listUser['standing'] = this.props.entry.standing;
    }

    if (listUser !== null && listUser !== undefined && !listUser.hasOwnProperty('strikes')) {
      listUser['strikes'] = 0;
    }
    if (
      listUser !== null && listUser !== undefined &&
      !listUser.hasOwnProperty('rank') &&
      this.props.entry.standing !== undefined
    ) {
      listUser['rank'] = !this.props.entry.standing.rank ? 0 : this.props.entry.standing.rank;
    } else if (listUser !== null && this.props.poolInfo.game_type === 'survivor') {
      listUser.rank = !this.props.entry.survivor_standing
        ? 0
        : this.props.entry.survivor_standing.rank;
    }
    let moneyFormat = null;
    let currencyImage = null;
    let acronymPrize = null;
    let availablePrize = null;
    switch (prizeCurrency) {
      case 'ticket':
        availablePrize = poolInfo.prize_count;
        moneyFormat = '0';
        acronymPrize = '';
        // currencyImage = ticketsIcon;
        break;
      case 'real':
        moneyFormat = '$0,000.00';
        availablePrize = numeral(poolInfo.available / 100).format(moneyFormat);
        acronymPrize = 'MX';
        // currencyImage = billete;
        break;
      case 'pickcoin':
        acronymPrize = 'PC';
        moneyFormat = '0,000';
        availablePrize = numeral(poolInfo.available / 100).format(moneyFormat);
        // currencyImage = pickcoin1;
        break;
      default:
    }

    /**
     * Bracket Tabla Posiiones
     **/
    if (this.props.type === 'bracket') {
      moneyFormat = this.props.poolInfo.entry_currency === 'real' ? '$0,000.00' : '0,000';
      currencyImage = this.props.poolInfo.entry_currency === 'real' ? billete : pickcoins;

      if (listUser) {
        let d = new Date();
        let userAvatar = null;
        let userNick = null
        if (this.props.userData.avatar) {
          userAvatar = this.props.userData.avatar === null ? avatar : `${this.props.userData.avatar_dir}${this.props.userData.avatar}`
          userNick = this.props.userData.nick;
        }
        if (this.props.userData.user) {
          userAvatar = this.props.userData.user.avatar === null ? avatar : `${this.props.userData.user.avatar_dir}${this.props.userData.user.avatar}`
          userNick = this.props.userData.user.nick;
        }
        if (userAvatar === null) {
          userAvatar = avatar;
          userNick = this.props.userData.nick;
        }
        rowUser = (
          <li
            // key={listUser.id}
            className="position-item user flex-row row-no-padding"
            style={{ paddingRight: '6px' }}
          >
            <div className="flex-col col-center posiciones-category friend"></div>
            <div className="posiciones-category rank quina flex-col col-15">
              <span>{listUser.bracket_standing.rank}°</span>
            </div>
            <div className="posiciones-category jugador quina col-30">
              <img
                src={ userAvatar }
                className="img-circle score-avatars"
              />
              <span
                style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                title={`${this.props.userData.nick}`}
              >
                {`${userNick}`}
              </span>
              <sup
                style={{
                  fontSize: 'smaller',
                  margin: '7px 0px 0 2px',
                }}
              >{`${listUser.number !== null ? listUser.number : ''}`}</sup>
            </div>
            {this.props.poolInfo.type !== "perfect" &&
              <div className="posiciones-category aciertos quina col-15">
                <span>
                  {`${
                    this.props.poolInfo.closed
                      ? listUser.bracket_standing.points
                      : listUser.bracket_standing.possible_points
                    }`}{' '}
                </span>
              </div>
            }
            <div className={`posiciones-category a-ganar quina ${this.props.poolInfo.type === 'perfect' ? 'col-20' : 'col-30'}`}>
              <AganarBracket
                poolInfo={this.props.poolInfo}
                userData={listUser}
                getAganar={this.props.getAganar}
                tiesDistribution={this.props.tiesDistribution}
                premios={this.props.premios}
                calculateTiesPrizes={this.props.calculateTiesPrizes}
              />
            </div>
            {this.props.poolInfo.type === "perfect" &&
              <div className={`posiciones-category alive flex-col col-10  quina ${listUser.bracket_standing.alive ? 'gano' : 'perdio'}`}>
                <i className={!listUser.bracket_standing.alive ? 'flex-container justify align ion-close-round' : 'flex-container justify align ion-checkmark-round'} />
              </div>
            }
          </li>
        );
      }

      if (this.state.mounted) {
        // console.log(this);
        if (this.props.weeklyList) {
          conditionalComponent = (
            <div className={`position-list border-gradient ${this.props.isVisible ? '' : 'hidden'}`}>
              <div className="btn wrap flex-row row-no-padding">
                <WeekControls
                  poolWeeks={this.state.poolWeeks}
                  currentWeek={this.state.currentWeek}
                  changeWeek={this.changeWeek}
                />
              </div>
              <div className="title-quiniela">
                <div className="title-wrapper">
                  <img src={posiciones} className="pool-icons tablas" />{' '}
                  <span className='font-chivo'>Tabla de Posiciones Semanales</span>
                </div>
              </div>
              <div className="position-header flex-row row-no-padding ">
                <div className="posiciones-category flex-col col-center">
                  <i className="ion-ios-people" style={{ fontSize: '1.5em' }}></i>
                </div>
                <div className="sortableClick posiciones-category flex-col-15 quina">
                  <span
                    onClick={() => {
                      this.props.sortPositionList('posiciones');
                    }}
                  >
                    Pos
                  </span>
                  {this.props.activeSort && this.props.activeSort.posiciones ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
                <div className="sortableClick posiciones-category flex-col col-40 quina">
                  <span
                    onClick={() => {
                      this.props.sortPositionList('jugadores');
                    }}
                  >
                    Jugador
                  </span>
                  {this.props.activeSort && this.props.activeSort.jugadores ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
                <div className="sortableClick posiciones-category flex-col col-15 quina">
                  <span
                    onClick={() => {
                      this.props.sortPositionList('aciertos');
                    }}
                  >
                    Aciertos
                  </span>
                  {this.props.activeSort && this.props.activeSort.aciertos ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
                <div
                  onClick={() => {
                    this.props.sortPositionList('premios');
                  }}
                  className=" sortableClick posiciones-category flex-col col-20 quina"
                >
                  {this.props.poolInfo.pool.closed ? <span>Ganó</span> : <span>A Ganar</span>}
                  {this.props.activeSort && this.props.activeSort.premios ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
              </div>
              <div className={`overflow no-border overflow-invisible overflow-y ${
                  this.props.type === 'lobby' ? 'overflow-300' : 'overflow-360'
                  }`}
              >
                <div className="lista-posiciones quiniela">
                  {rowUser}
                  {this.props.submittingSearch ? (
                    <div className="flex-container justify align">
                      <i className="ion-load-a loading" />
                    </div>
                  ) : this.props.posiciones.items.length === 0 ? (
                    <div className="flex-container column justify align">
                      <i className="ion-sad big-ionicon" />
                      <span className="text-center">
                        Lo sentimos, no encontramos ningún pickwinero.
                      </span>
                    </div>
                  ) : (
                    <div style={{height: 'calc(25vh + '+$('.panelMisQuinielas').height()/2+'px)', overflowY: 'scroll'}}>
                      <InfiniteScroll
                        pageStart={1}
                        loadMore={this.loadMoreRows}
                        hasMore={this.props.posiciones.page === this.props.posiciones.pages ? false : true}
                        loader={<Loading key={'labelLoader00'} label="..." />}
                        useWindow={false}
                      >
                        {this.props.posiciones.items.map((pos, i) => {
                          let props = {key: i, index: i, style: null}
                          return this.rowBracketRenderer(props);
                        })}
                      </InfiniteScroll>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        } else {
          let styleTable = {};
          if (this.props.myRegisters !== undefined) {
            styleTable = {
              height: '100%',
            }
          }

          conditionalComponent = (
            <div className={`position-list border-gradient ${this.props.isVisible ? '' : 'hidden'}`}>
              <div className="title-quiniela">
                <div className="title-wrapper">
                  <img src={posiciones} className="pool-icons tablas" />{' '}
                  <span className='font-chivo'>Tabla de Posiciones</span>
                </div>
              </div>
              <SearchableInput
                placeholder={'Buscar a pickwineros por su nick '}
                value={this.state.searchableNick}
                fetchStandings={this.props.fetchSearchableStandings}
                changeSearchNick={this.props.changeSearchNick}
                resetValue={this.resetValue}
                submitting={this.props.submittingSearch}
              />
              <div className="position-header flex-row row-no-padding ">
                <div className="posiciones-category flex-col col-center">
                  <i className="ion-ios-people" style={{ fontSize: '1.5em' }}></i>
                </div>
                <div
                  onClick={() => {
                    this.props.sortPositionList('posiciones');
                  }}
                  className="sortableClick posiciones-category rank quina flex-col col-15"
                >
                  <span>Pos</span>
                  {this.props.activeSort && this.props.activeSort.posiciones ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
                <div className="sortableClick posiciones-category flex-col  col-30 quina">
                  <span
                    onClick={() => {
                      this.props.sortPositionList('jugadores');
                    }}
                  >
                    Jugador
                  </span>
                  {this.props.activeSort.jugadores ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
                {this.props.poolInfo.type !== "perfect" &&
                  <div className="sortableClick posiciones-category flex-col col-15 quina">
                    <span
                      onClick={() => {
                        this.props.sortPositionList('aciertos');
                      }}
                    >
                      Puntos
                    </span>
                    {this.props.activeSort.aciertos ? (
                      <i className="ion-arrow-up-b" />
                    ) : (
                        <i className="ion-arrow-down-b" />
                      )}
                  </div>
                }
                <div className={`sortableClick posiciones-category flex-col quina ${this.props.poolInfo.type === 'perfect' ? 'col-20' : 'col-30'}`}>
                  <span
                    onClick={() => {
                      this.props.sortPositionList('premios');
                    }}
                  >
                    Premio
                  </span>
                    {this.props.activeSort.premios ? (
                      <i className="ion-arrow-up-b" />
                    ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
                {this.props.poolInfo.type === "perfect" &&
                  <div className="sortableClick posiciones-category flex-col col-10 quina">
                    <span>Vivo</span>
                    {this.props.activeSort && this.props.activeSort.alive ? (
                      <i className="ion-arrow-up-b" />
                    ) : (
                        <i className="ion-arrow-down-b" />
                      )}
                  </div>
                }
              </div>
              <div
                className={`overflow no-border overflow-invisible overflow-y ${
                  this.props.type === 'lobby' ? 'overflow-300' : 'overflow-360'
                  }`}
                style={styleTable}
              >
                <div className="lista-posiciones quiniela">
                  {rowUser}
                  {this.props.submittingSearch ? (
                    <div className="flex-container justify align">
                      <i className="ion-load-a loading" />
                    </div>
                  ) : !this.props.posiciones ||
                    !this.props.posiciones.hasOwnProperty('items') ||
                    (this.props.posiciones.items && this.props.posiciones.items.length && this.props.posiciones.items.length < 1) ? (
                        <div className="flex-container column justify align">
                          <i className="ion-sad big-ionicon" />
                          <span className="text-center">
                            Lo sentimos, no encontramos ningún pickwinero.
                      </span>
                        </div>
                      ) : (
                        <div style={{height: 'calc(25vh + '+$('.panelMisQuinielas').height()/2+'px)', overflowY: 'scroll'}}>
                          <InfiniteScroll
                            pageStart={1}
                            loadMore={this.loadMoreRows}
                            hasMore={this.props.posiciones.page === this.props.posiciones.pages ? false : true}
                            loader={<Loading key={'labelLoader00'} label="..." />}
                            useWindow={false}
                          >
                            {this.props.posiciones.items.map((pos, i) => {
                              let props = {key: i, index: i, style: null}
                              return this.rowBracketRenderer(props);
                            })}
                          </InfiniteScroll>
                        </div>
                      )}
                </div>
              </div>
            </div>
          );
        }
      } else {
        conditionalComponent = <div id="loading">Cargando...</div>;
      }
    } else if (
      (this.props.type === 'survivor' || this.props.type === 'survivorLobby') &&
      (this.props.poolInfo.sport_id !== 6 && this.props.poolInfo.sport_id !== 7)
    ) {
      let strikesEnabled = this.props.poolInfo.strikes_enabled;

      let survivorHeader = null;

      if (!strikesEnabled) {
        survivorHeader = (
          <div className="position-header ">
            <div className="posiciones-category friend quina flex-col  ">
              <i className="ion-ios-people" />
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('posiciones');
              }}
              className="sortableClick posiciones-category rank quina flex-col col-15"
            >
              <span>Pos</span>
              {this.props.activeSort && this.props.activeSort.posiciones ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('jugadores');
              }}
              className="sortableClick posiciones-category flex-col col-25 quina"
            >
              <span>Jugador</span>
              {this.props.activeSort && this.props.activeSort.jugadores ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('premios');
              }}
              className="sortableClick posiciones-category flex-col col-30 quina justify-end"
            >
              <span>Premio</span>
              {this.props.activeSort && this.props.activeSort.premios ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div className="posiciones-category sortableClick flex-col col-15 alive quina ">
              <span>Vivo</span>
              {this.props.activeSort && this.props.activeSort.alive ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
          </div>
        );
      } else {
        survivorHeader = (
          <div className="position-header ">
            <div className="posiciones-category friend quinacol-no-padding flex-col col-5 ">
              <i className="ion-ios-people" />
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('posiciones');
              }}
              className="sortableClick posiciones-category rank quina col-no-padding flex-col col-10"
            >
              <span>Pos</span>
              {this.props.activeSort && this.props.activeSort.posiciones ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('jugadores');
              }}
              className="sortableClick posiciones-category flex-col col-30 col-no-padding quina"
            >
              <span>Jugador</span>
              {this.props.activeSort && this.props.activeSort.jugadores ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div className="sortableClick posiciones-category flex-col col-15 strikes col-no-padding quina">
              <span>Strikes</span>
              {this.props.activeSort && this.props.activeSort.strikes ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('premios');
              }}
              className="sortableClick posiciones-category flex-col col-25 quina col-no-padding justify-end"
            >
              <span>Premio</span>
              {this.props.activeSort && this.props.activeSort.premios ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('alive');
              }}
              className="sortableClick posiciones-category flex-col col-15 col-no-padding quina"
            >
              <span>Vivo</span>
              {this.props.activeSort && this.props.activeSort.alive ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
          </div>
        );
      }

      //let userData = utils.getUserInfo();

      // console.log(listUser, userData);
      moneyFormat = this.props.poolInfo.entry_currency === 'real' ? '$0,000.00' : '0,000';
      currencyImage = this.props.poolInfo.entry_currency === 'real' ? billete : pickcoins;

      if (listUser && listUser?.standing) {
        // console.log(listUser);
        if (strikesEnabled) {
          rowUser = (
            <div key={listUser.id} className={`position-item  survivor user flex-row`}>
              <div className="posiciones-category friend flex-col col-5 quina" />
              <div className="posiciones-category rank flex-col col-10 quina">
                <span className="picks-rank">{`${listUser.rank}`}°</span>
              </div>
              <div className="posiciones-category jugador flex-col col-30 quina" style={{marginLeft: '-6px'}}>
                <img
                  src={
                    this.props.userData.avatar === null
                      ? avatar
                      : `${this.props.userData.avatar_dir}${this.props.userData.avatar}`
                  }
                  className="img-circle score-avatars"
                />
                <span
                  style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                >
                  {`${this.props.userData.nick}`}
                </span>
                <sup
                  style={{
                    fontSize: 'smaller',
                    margin: '7px 0px 0 2px',
                  }}
                >{`${listUser.number !== null ? listUser.number : ''}`}</sup>
              </div>
              <div className="flex-col posiciones-category strikes col-no-padding col-15 quina" style={{marginLeft: '3px'}}>
                <Strikes currentUser={listUser} maxStrikes={this.props.poolInfo.max_strikes} />
              </div>
              {!listUser?.survivor_standing?.elimination_week_id &&
                listUser?.survivor_standing?.alive &&
                this.props.poolInfo.status !== 'closed' ? (
                  <div className="flex-col col-25"></div>
                ) : !listUser?.survivor_standing?.elimination_week_id &&
                  !listUser?.survivor_standing?.alive &&
                  this.props.poolInfo.status !== 'closed' ? (
                    <div className="flex-col col-25">
                      <div className="flex-container justify-end align"> PD </div>
                    </div>
                  ) : (
                    <AganarSurvivor
                      gameInfo={this.props.poolInfo}
                      userData={listUser}
                      getAganar={this.props.getAganar}
                      prizesList={this.props.premios}
                      tiesDistribution={this.props.tiesDistribution}
                      standings={this.props.standings}
                    />
                  )}
              <div
                className={`posiciones-category alive flex-col col-15  quina ${
                  listUser?.survivor_standing?.alive ? 'gano' : 'perdio'
                  }`}
              >
                <i
                  className={
                    !listUser?.survivor_standing?.alive
                      ? 'flex-container justify align ion-close-round'
                      : 'flex-container justify align ion-checkmark-round'
                  }
                />
              </div>
            </div>
          );
        } else {
          rowUser = (
            <div
              // key={listUser.id}
              className={`position-item survivor user flex-row`}
            >
              <div className="posiciones-category friend quina flex-container align justify"></div>
              <div className="posiciones-category rank flex-col col-15 quina text-right" style={{paddingRight: '2px'}}>
                <span className="picks-rank">{`${listUser.rank}`}°</span>
              </div>
              <div className="posiciones-category jugador flex-col col-30 quina" style={{paddingLeft: '15px'}}>
                <img
                  src={
                    this.props.userData.avatar === null
                      ? avatar
                      : `${this.props.userData.avatar_dir}${this.props.userData.avatar}`
                  }
                  className="img-circle score-avatars"
                />
                <span
                  style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  title={`${this.props.userData.nick}`}
                >
                  {`${this.props.userData.nick}`}
                </span>
                <sup
                  style={{
                    fontSize: 'smaller',
                    margin: '7px 0px 0 2px',
                  }}
                >{`${listUser.number !== null ? listUser.number : ''}`}</sup>
              </div>
              {!listUser?.survivor_standing?.elimination_week_id &&
                listUser?.survivor_standing?.alive &&
                this.props.poolInfo.status !== 'closed' ? (
                  <div className="flex-col col-30"></div>
                ) : !listUser?.survivor_standing?.elimination_week_id &&
                  !listUser?.survivor_standing?.alive &&
                  this.props.poolInfo.status !== 'closed' ? (
                    <div className="flex-col col-30">
                      <div className="flex-container justify-end align"> PD </div>
                    </div>
                  ) : (
                    <AganarSurvivor
                      gameInfo={this.props.poolInfo}
                      userData={listUser}
                      getAganar={this.props.getAganar}
                      prizesList={this.props.premios}
                      tiesDistribution={this.props.tiesDistribution}
                      standings={this.props.standings}
                    />
                  )}
              <div
                className={`posiciones-category alive flex-col col-15  quina text-center ${
                  listUser?.survivor_standing?.alive ? 'gano' : 'perdio'
                  }`}
              >
                <i
                  className={
                    !listUser?.survivor_standing?.alive ? 'ion-close-round' : 'ion-checkmark-round'
                  }
                />
              </div>
            </div>
          );
        }
      }

      let miSizeList =
        this.props.type === 'survivor' ? this.handleListSize(this.props.selectedEntry) : null;

      conditionalComponent = this.state.mounted ? (
        <div
          className={`position-list border-gradient flex-row row-no-padding  ${
            this.props.type === 'survivorLobby' ? 'survivorStandings' : ''
            } ${this.props.isVisible ? '' : 'hidden'}`}
        >
          <div className="flex-col col-no-padding">
            <div className="flex-row row-no-padding row-center title-quiniela">
              <div className="title-wrapper  flex-row row-no-padding row-center">
                <img src={posiciones} className="pool-icons tablas" />
                <span className='font-chivo'>Tabla de Posiciones</span>
              </div>
            </div>
            <SearchableInput
              placeholder={'Buscar a pickwineros por su nick '}
              value={this.state.searchableNick}
              fetchStandings={this.props.fetchSearchableStandings}
              changeSearchNick={this.props.changeSearchNick}
              resetValue={this.resetValue}
              submitting={this.props.submittingSearch}
            />
            {survivorHeader}
            {rowUser}
            <div className="flex-row row-no-padding">
              <div
                className={`  overflow no-border overflow-invisible overflow-y flex-col
              ${
                  this.props.type === 'survivorLobby'
                    ? ' overflow-360 survivor-standings'
                    : 'overflow-360'
                  }`}
                style={this.props.type === 'survivor' ? { height: `${miSizeList}px` } : {}}
              >
                <div className="lista-posiciones survivor">
                  {this.props.submittingSearch ? (
                    <div className="flex-container justify align">
                      <i className="ion-load-a loading" />
                    </div>
                  ) : this.props.posiciones.items.length === 0 ? (
                    <div className="flex-container column justify align">
                      <i className="ion-sad big-ionicon" />
                      <span className="text-center">
                        Lo sentimos, no encontramos ningún pickwinero.
                      </span>
                    </div>
                  ) : (
                        // <InfiniteLoader
                        //   isRowLoaded={this.isRowLoaded}
                        //   loadMoreRows={this.loadMoreRows}
                        //   rowCount={this.props.posiciones.count}
                        //   threshold={1}
                        // >
                        //   {({ onRowsRendered, registerChild }) => (
                        //     <AutoSizer>
                        //       {({ height, width }) => (
                        //         <List
                        //           ref={(registerChild) => (this.list = registerChild)}
                        //           height={height}
                        //           overscanRowCount={30}
                        //           onRowsRendered={onRowsRendered}
                        //           rowCount={this.props.posiciones.items.length}
                        //           rowHeight={30}
                        //           rowRenderer={
                        //             strikesEnabled
                        //               ? this.rowSurvivorSoccerRenderer
                        //               : this.rowSurvivorRenderer
                        //           }
                        //           width={width}
                        //         />
                        //       )}
                        //     </AutoSizer>
                        //   )}
                        // </InfiniteLoader>

                        <div style={{height: '100%', overflowY: 'scroll'}}>
                          <InfiniteScroll
                            pageStart={1}
                            loadMore={this.loadMoreRows}
                            hasMore={this.props.posiciones.page === this.props.posiciones.pages ? false : true}
                            loader={<Loading key={'labelLoader00'} label="..." />}
                            useWindow={false}
                          >
                            {this.props.posiciones.items.map((pos, i) => {
                              let props = {key: i, index: i, style: null}
                              return strikesEnabled ? this.rowSurvivorSoccerRenderer(props) : this.rowSurvivorRenderer(props);
                            })}
                          </InfiniteScroll>
                        </div>
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
          <div id="loading">Cargando...</div>
        );
    } else if (
      (this.props.type === 'survivor' || this.props.type === 'survivorLobby') &&
      (this.props.poolInfo.sport_id === 6 || this.props.poolInfo.sport_id === 7)
    ) {
      let strikesEnabled = this.props.poolInfo.strikes_enabled;
      // console.log('%c ------Tabla Survivor------', 'font-size:2em;');
      // console.log(strikesEnabled);
      // console.log(this.props.poolInfo);

      let survivorHeader = null;

      if (!strikesEnabled) {
        survivorHeader = (
          <div className="position-header ">
            <div className="posiciones-category friend quina flex-col  ">
              <i className="ion-ios-people" />
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('posiciones');
              }}
              className="sortableClick posiciones-category rank quina flex-col col-15"
            >
              <span>Pos</span>
              {this.props.activeSort && this.props.activeSort.posiciones ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('jugadores');
              }}
              className="sortableClick posiciones-category flex-col col-25 quina"
            >
              <span>Jugador</span>
              {this.props.activeSort && this.props.activeSort.jugadores ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('premios');
              }}
              className="sortableClick posiciones-category flex-col col-30 quina justify-end"
            >
              <span>Premio</span>
              {this.props.activeSort && this.props.activeSort.premios ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div className="posiciones-category sortableClick flex-col col-15 alive quina ">
              <span>Vivo</span>
              {this.props.activeSort && this.props.activeSort.alive ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
          </div>
        );
      } else {
        // console.log('%c strikesEnabled', 'color:#64D240; font-size:2em;');
        survivorHeader = (
          <div className="position-header ">
            <div className="posiciones-category friend quinacol-no-padding flex-col col-5 ">
              <i className="ion-ios-people" />
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('posiciones');
              }}
              className="sortableClick posiciones-category rank quina col-no-padding flex-col col-10"
            >
              <span>Pos</span>
              {this.props.activeSort && this.props.activeSort.posiciones ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('jugadores');
              }}
              className="sortableClick posiciones-category flex-col col-30 col-no-padding quina"
            >
              <span>Jugador</span>
              {this.props.activeSort && this.props.activeSort.jugadores ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div className="sortableClick posiciones-category flex-col col-15 strikes col-no-padding quina">
              <span>Strikes</span>
              {this.props.activeSort && this.props.activeSort.strikes ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('premios');
              }}
              className="sortableClick posiciones-category flex-col col-25 quina col-no-padding justify-end"
            >
              <span>Premio</span>
              {this.props.activeSort && this.props.activeSort.premios ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={() => {
                this.props.sortPositionList('alive');
              }}
              className="sortableClick posiciones-category flex-col col-15 col-no-padding quina"
            >
              <span>Vivo</span>
              {this.props.activeSort && this.props.activeSort.alive ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
          </div>
        );
      }

      //let userData = utils.getUserInfo();

      // console.log(listUser, userData);
      moneyFormat = this.props.poolInfo.entry_currency === 'real' ? '$0,000.00' : '0,000';
      currencyImage = this.props.poolInfo.entry_currency === 'real' ? billete : pickcoins;

      if (listUser) {
        // console.log(listUser);
        if (strikesEnabled) {
          rowUser = (
            <div key={listUser.id} className={`position-item  survivor user flex-row row-no-padding row-center`}>
              <div className="posiciones-category friend flex-col col-5 quina" />
              <div className="posiciones-category rank flex-col col-10 quina">
                <span className="picks-rank">{`${listUser.rank}`}°</span>
              </div>
              <div className="posiciones-category flex-col col-30 jugador quina row-center" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                <img
                  src={
                    this.props.userData.avatar === null
                      ? avatar
                      : `${this.props.userData.avatar_dir}${this.props.userData.avatar}`
                  }
                  className="img-circle score-avatars"
                />
                <span>
                  {`${this.props.userData.nick}`}
                </span>
                <sup
                  style={{
                    fontSize: 'smaller',
                    margin: '7px 0px 0 2px',
                  }}
                >{`${listUser.number !== null ? listUser.number : ''}`}</sup>
              </div>
              <div className="flex-col posiciones-category strikes col-no-padding col-15 quina">
                <SurvivorContestStrikes standing={listUser} poolInfo={this.props.poolInfo} />
              </div>
              {!listUser?.survivor_standing?.elimination_week_id &&
                listUser?.survivor_standing?.alive &&
                this.props.poolInfo.status !== 'closed' ? (
                  <div className="flex-col col-25"></div>
                ) : !listUser?.survivor_standing?.elimination_week_id &&
                  !listUser?.survivor_standing?.alive &&
                  this.props.poolInfo.status !== 'closed' ? (
                    <div className="flex-col col-25">
                      <div className="flex-container justify-end align"> PD </div>
                    </div>
                  ) : (
                    <AganarSurvivor
                      gameInfo={this.props.poolInfo}
                      userData={listUser}
                      getAganar={this.props.getAganar}
                      prizesList={this.props.premios}
                      tiesDistribution={this.props.tiesDistribution}
                      standings={this.props.standings}
                    />
                  )}
              <div className={`posiciones-category alive flex-col col-15  quina ${listUser?.survivor_standing?.alive ? 'gano' : 'perdio'}`} style={{minHeight: '30px', display: 'flex'}}>
                <i
                  className={
                    !listUser?.survivor_standing?.alive
                      ? 'flex-container justify align ion-close-round'
                      : 'flex-container justify align ion-checkmark-round'
                  }
                />
              </div>
            </div>
          );
        } else {
          rowUser = (
            <div
              // key={listUser.id}
              className={`position-item survivor user flex-row`}
            >
              <div className="posiciones-category friend quina flex-col " />
              <div className="posiciones-category rank flex-col col-15 quina">
                <span className="picks-rank">{`${listUser.rank}`}°</span>
              </div>
              <div className="posiciones-category jugador flex-col col-30 quina">
                <img
                  src={
                    this.props.userData.avatar === null
                      ? avatar
                      : `${this.props.userData.avatar_dir}${this.props.userData.avatar}`
                  }
                  className="img-circle score-avatars"
                />
                <span
                  style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  title={`${this.props.userData.nick}`}
                >
                  {`${this.props.userData.nick}`}
                </span>
                <sup
                  style={{
                    fontSize: 'smaller',
                    margin: '7px 0px 0 2px',
                  }}
                >{`${listUser.number !== null ? listUser.number : ''}`}</sup>
              </div>
              {!listUser?.survivor_standing?.elimination_week_id &&
                listUser?.survivor_standing?.alive &&
                this.props.poolInfo.status !== 'closed' ? (
                  <div className="flex-col col-30"></div>
                ) : !listUser?.survivor_standing?.elimination_week_id &&
                  !listUser?.survivor_standing?.alive &&
                  this.props.poolInfo.status !== 'closed' ? (
                    <div className="flex-col col-30">
                      <div className="flex-container justify-end align"> PD </div>
                    </div>
                  ) : (
                    <AganarSurvivor
                      gameInfo={this.props.poolInfo}
                      userData={listUser}
                      getAganar={this.props.getAganar}
                      prizesList={this.props.premios}
                      tiesDistribution={this.props.tiesDistribution}
                      standings={this.props.standings}
                    />
                  )}
              <div
                className={`posiciones-category alive flex-col col-15  quina ${
                  listUser?.survivor_standing?.alive ? 'gano' : 'perdio'
                  }`}
              >
                <i
                  className={
                    !listUser?.survivor_standing?.alive ? 'ion-close-round' : 'ion-checkmark-round'
                  }
                />
              </div>
            </div>
          );
        }
      }

      let miSizeList =
        this.props.type === 'survivor' ? this.handleListSize(this.props.selectedEntry) : null;

      conditionalComponent = this.state.mounted ? (
        <div
          className={`position-list border-gradientflex-row row-no-padding  ${
            this.props.type === 'survivorLobby' ? 'survivorStandings' : ''
            } ${this.props.isVisible ? '' : 'hidden'}`}
        >
          <div className="flex-col col-no-padding">
            <div className="flex-row row-no-padding row-center title-quiniela">
              <div className="title-wrapper  flex-row row-no-padding row-center">
                <img src={posiciones} className="pool-icons tablas" />
                <span className='font-chivo'>Tabla de Posiciones</span>
              </div>
            </div>
            <SearchableInput
              placeholder={'Buscar a pickwineros por su nick '}
              value={this.state.searchableNick}
              fetchStandings={this.props.fetchSearchableStandings}
              changeSearchNick={this.props.changeSearchNick}
              resetValue={this.resetValue}
              submitting={this.props.submittingSearch}
            />
            {survivorHeader}
            {rowUser}
            <div className="flex-row row-no-padding">
              <div
                className={`overflow no-border overflow-invisible overflow-y flex-col
                ${
                  this.props.type === 'survivorLobby'
                    ? 'overflow-360 survivor-standings'
                    : 'overflow-360'
                  }`}
                style={this.props.type === 'survivor' ? { height: `${miSizeList}px` } : {}}
              >
                <div className="lista-posiciones survivor">
                  {this.props.submittingSearch ? (
                    <div className="flex-container justify align">
                      <i className="ion-load-a loading" />
                    </div>
                  ) : this.props.posiciones.items.length === 0 ? (
                    <div className="flex-container column justify align">
                      <i className="ion-sad big-ionicon" />
                      <span className="text-center">
                        Lo sentimos, no encontramos ningún pickwinero.
                      </span>
                    </div>
                  ) : (
                        // <InfiniteLoader
                        //   isRowLoaded={this.isRowLoaded}
                        //   loadMoreRows={this.loadMoreRows}
                        //   rowCount={this.props.posiciones.count}
                        //   threshold={1}
                        // >
                        //   {({ onRowsRendered, registerChild }) => (
                        //     <AutoSizer>
                        //       {({ height, width }) => (
                        //         <List
                        //           ref={(registerChild) => (this.list = registerChild)}
                        //           height={height}
                        //           overscanRowCount={30}
                        //           onRowsRendered={onRowsRendered}
                        //           rowCount={this.props.posiciones.items.length}
                        //           rowHeight={30}
                        //           rowRenderer={this.rowSurvivorContestRenderer}
                        //           width={width}
                        //         />
                        //       )}
                        //     </AutoSizer>
                        //   )}
                        // </InfiniteLoader>
                        <div style={{height: '100%', overflowY: 'scroll'}}>
                          <InfiniteScroll
                            pageStart={1}
                            loadMore={this.loadMoreRows}
                            hasMore={this.props.posiciones.page === this.props.posiciones.pages ? false : true}
                            loader={<Loading key={'labelLoader00'} label="..." />}
                            useWindow={false}
                          >
                            {this.props.posiciones.items.map((pos, i) => {
                              let props = {key: i, index: i, style: null}
                              return this.rowSurvivorContestRenderer(props);
                            })}
                          </InfiniteScroll>
                        </div>
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
          <div id="loading">Cargando...</div>
        );
    } else if (this.props.type === 'group_pool') {
      return null;
    } else {
      /**
       * Quiniela Tabla Posiiones
       **/
      moneyFormat = this.props.poolInfo.pool.entry_currency === 'real' ? '$0,000.00' : '0,000';
      currencyImage = this.props.poolInfo.pool.entry_currency === 'real' ? billete : pickcoins;
      // console.log('%c Quiniela Table', 'color:rgb(190, 219, 41); font-size:1.2em;');
      if (listUser) {
        // console.log(this.props.userData);

        // check parlay blocks to begining
        let parlayDeadlines = this.props.parlayDeadlines;
        let d = new Date();
        let today = d.getTime();
        let activeBloks = 0;
        parlayDeadlines.map((e) => {
          let parlayDate = Date.parse(e);
          if (today > parlayDate) {
            activeBloks++;
          }
        })

        rowUser = (
          <li
            // key={listUser.id}
            className="position-item user flex-row row-no-padding"
            style={{ paddingRight: '6px' }}
          >
            <div className="flex-col col-center posiciones-category friend"></div>
            {this.props.poolInfo.pool.parlay_flag === null && (
              <div className="posiciones-category rank quina col-15 ">
                <span>{listUser.standing.rank}°</span>
              </div>
            )}
            <div className="posiciones-category jugador quina col-30">
              <img
                src={
                  this.props.userData.avatar === null
                    ? avatar
                    : `${this.props.userData.avatar_dir}${this.props.userData.avatar}`
                }
                className="img-circle score-avatars"
              />
              <span
                style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                title={`${this.props.userData.nick}`}
              >
                {`${this.props.userData.nick}`}
              </span>
              <sup
                style={{
                  fontSize: 'smaller',
                  margin: '7px 0px 0 2px',
                }}
              >{`${listUser.number !== null ? listUser.number : ''}`}</sup>
            </div>
            <div className="posiciones-category aciertos quina col-15">
              {this.props.poolInfo.pool.parlay_flag === null && (
                <span>
                  {`${
                    this.props.poolInfo.pool.closed
                      ? listUser.standing.points
                      : listUser.standing.total_possibly_correct
                    }`}{' '}
                </span>
              ) || (
                <span>{this.props.poolInfo.pool.closed
                  ? listUser.standing.points
                  : listUser.standing.total_possibly_correct} / {activeBloks}</span>
              )}
            </div>
            <div className="posiciones-category a-ganar quina col-30"  style={{ paddingRight: '6px', justifyContent: 'flex-end' }}> 
              <Aganar
                poolInfo={this.props.poolInfo.pool}
                userData={listUser}
                getAganar={this.props.getAganar}
              />
            </div>
          </li>
        );
      }

      if (this.state.mounted) {
        // console.log(this);
        if (this.props.weeklyList) {
          conditionalComponent = (
            <div className={`position-list border-gradient ${this.props.isVisible ? '' : 'hidden'}`}>
              <div className="btn wrap flex-row row-no-padding">
                <WeekControls
                  poolWeeks={this.state.poolWeeks}
                  currentWeek={this.state.currentWeek}
                  changeWeek={this.changeWeek}
                />
              </div>
              <div className="title-quiniela">
                <div className="title-wrapper">
                  <img src={posiciones} className="pool-icons tablas" />{' '}
                  <span className='font-chivo'>Tabla de Posiciones Semanales</span>
                </div>
              </div>
              <div className="position-header flex-row row-no-padding ">
                <div className="posiciones-category flex-col col-center">
                  <i className="ion-ios-people" style={{ fontSize: '1.5em' }}></i>
                </div>
                <div className="sortableClick posiciones-category flex-col-15 quina">
                  <span
                    onClick={() => {
                      this.props.sortPositionList('posiciones');
                    }}
                  >
                    Pos
                  </span>
                  {this.props.activeSort && this.props.activeSort.posiciones ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
                <div className="sortableClick posiciones-category flex-col col-40 quina">
                  <span
                    onClick={() => {
                      this.props.sortPositionList('jugadores');
                    }}
                  >
                    Jugador
                  </span>
                  {this.props.activeSort && this.props.activeSort.jugadores ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
                <div className="sortableClick posiciones-category flex-col col-15 quina">
                  <span
                    onClick={() => {
                      this.props.sortPositionList('aciertos');
                    }}
                  >
                    Aciertos
                  </span>
                  {this.props.activeSort && this.props.activeSort.aciertos ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
                <div
                  onClick={() => {
                    this.props.sortPositionList('premios');
                  }}
                  className=" sortableClick posiciones-category flex-col col-20 quina"
                >
                  {this.props.poolInfo.pool.closed ? <span>Ganó</span> : <span>A Ganar</span>}
                  {this.props.activeSort && this.props.activeSort.premios ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
              </div>
              <div
                className={`overflow no-border overflow-invisible overflow-y ${
                  this.props.type === 'lobby' ? 'overflow-300' : 'overflow-360'
                  }`}
              >
                <div className="lista-posiciones quiniela">
                  {rowUser}
                  {this.props.submittingSearch ? (
                    <div className="flex-container justify align">
                      <i className="ion-load-a loading" />
                    </div>
                  ) : this.props.posiciones.items.length === 0 ? (
                    <div className="flex-container column justify align">
                      <i className="ion-sad big-ionicon" />
                      <span className="text-center">
                        Lo sentimos, no encontramos ningún pickwinero.
                      </span>
                    </div>
                  ) : (
                        // <InfiniteLoader
                        //   isRowLoaded={this.isRowLoaded}
                        //   loadMoreRows={this.loadMoreRows}
                        //   rowCount={this.props.posiciones.count}
                        //   threshold={1}
                        // >
                        //   {({ onRowsRendered, registerChild }) => (
                        //     <AutoSizer>
                        //       {({ height, width }) => (
                        //         <List
                        //           ref={(registerChild) => (this.list = registerChild)}
                        //           height={this.props.entry ? height - 30 : height}
                        //           overscanRowCount={30}
                        //           onRowsRendered={onRowsRendered}
                        //           rowCount={this.props.posiciones.items.length}
                        //           rowHeight={30}
                        //           rowRenderer={this.rowRenderer}
                        //           width={width}
                        //         />
                        //       )}
                        //     </AutoSizer>
                        //   )}
                        // </InfiniteLoader>
                        <div style={{height: '100%', overflowY: 'scroll'}}>
                          <InfiniteScroll
                            pageStart={1}
                            loadMore={this.loadMoreRows}
                            hasMore={this.props.actualPage >= this.props.posiciones.pages ? false : true}
                            loader={<Loading key={'labelLoader00'} label="..." />}
                            useWindow={false}
                          >
                            {this.props.posiciones.items.map((pos, i) => {
                              let props = {key: i, index: i, style: null}
                              return this.rowRenderer(props);
                            })}
                          </InfiniteScroll>
                        </div>
                      )}
                </div>
              </div>
            </div>
          );
        } else {
          conditionalComponent = (
            <div className={`position-list border-gradient ${this.props.isVisible ? '' : 'hidden'}`}>
              <div className="title-quiniela">
                <div className="title-wrapper">
                  <img src={posiciones} className="pool-icons tablas" />{' '}
                  <span className='font-chivo'>Tabla de Posiciones</span>
                </div>
              </div>
              <SearchableInput
                placeholder={'Buscar a pickwineros por su nick '}
                value={this.state.searchableNick}
                fetchStandings={this.props.fetchSearchableStandings}
                changeSearchNick={this.props.changeSearchNick}
                resetValue={this.resetValue}
                submitting={this.props.submittingSearch}
              />
              <div className="position-header flex-row row-no-padding ">
                <div className="posiciones-category flex-col col-center">
                  <i className="ion-ios-people" style={{ fontSize: '1.5em' }}></i>
                </div>
                {this.props.poolInfo.pool.parlay_flag === null && (
                  <div className="sortableClick posiciones-category flex-col col-15 quina">
                    <span
                      onClick={() => {
                        this.props.sortPositionList('posiciones');
                      }}
                    >
                      Pos
                    </span>
                    {this.props.activeSort.posiciones && (
                      <i className="ion-arrow-up-b" />
                    ) || (
                      <i className="ion-arrow-down-b" />
                    )}
                  </div>
                )}
                <div className="sortableClick posiciones-category flex-col  col-30 quina">
                  <span
                    onClick={() => {
                      this.props.sortPositionList('jugadores');
                    }}
                  >
                    Jugador
                  </span>
                  {this.props.activeSort.jugadores ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
                <div className="sortableClick posiciones-category flex-col col-15 quina">
                  <span
                    onClick={() => {
                      this.props.sortPositionList('aciertos');
                    }}
                  >
                    Aciertos
                  </span>
                  {this.props.activeSort.aciertos ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
                <div
                  onClick={() => {
                    this.props.sortPositionList('premios');
                  }}
                  className=" sortableClick posiciones-category flex-col col-30 quina"
                >
                  {this.props.poolInfo.pool.closed ? <span>Ganó</span> : <span>A Ganar</span>}
                  {this.props.activeSort.premios ? (
                    <i className="ion-arrow-up-b" />
                  ) : (
                      <i className="ion-arrow-down-b" />
                    )}
                </div>
              </div>
              <div
                className={`overflow no-border overflow-invisible overflow-y ${
                  this.props.type === 'lobby' ? 'overflow-300' : 'overflow-360'
                  }`}
              >
                <div className="lista-posiciones quiniela">
                  {rowUser}
                  {this.props.submittingSearch ? (
                    <div className="flex-container justify align">
                      <i className="ion-load-a loading" />
                    </div>
                  ) : !this.props.posiciones ||
                    !this.props.posiciones.hasOwnProperty('items') ||
                    (this.props.posiciones.items && this.props.posiciones.items.length && this.props.posiciones.items.length < 1) ? (
                        <div className="flex-container column justify align">
                          <i className="ion-sad big-ionicon" />
                          <span className="text-center">
                            Lo sentimos, no encontramos ningún pickwinero.
                      </span>
                        </div>
                      ) : (
                        // <InfiniteLoader
                        //   isRowLoaded={this.isRowLoaded}
                        //   loadMoreRows={this.loadMoreRows}
                        //   rowCount={this.props.posiciones.count}
                        //   threshold={1}
                        // >
                        //   {({ onRowsRendered, registerChild }) => (
                        //     <AutoSizer>
                        //       {({ height, width }) => (
                        //         <List
                        //           ref={(registerChild) => (this.list = registerChild)}
                        //           height={this.props.entry ? height - 30 : height}
                        //           overscanRowCount={30}
                        //           onRowsRendered={onRowsRendered}
                        //           rowCount={
                        //             this.props.posiciones && this.props.posiciones.items && this.props.posiciones.items.length ? this.props.posiciones.items.length : 30
                        //           }
                        //           rowHeight={30}
                        //           rowRenderer={this.rowRenderer}
                        //           width={width}
                        //         />
                        //       )}
                        //     </AutoSizer>
                        //   )}
                        // </InfiniteLoader>
                        <div style={{height: '100%', overflowY: 'scroll'}}>
                          <InfiniteScroll
                            pageStart={1}
                            loadMore={this.loadMoreRows}
                            hasMore={this.props.actualPage >= this.props.posiciones.pages ? false : true}
                            loader={<Loading key={'labelLoader00'} label="..." />}
                            useWindow={false}
                          >
                            {this.props.posiciones.items.map((pos, i) => {
                              let props = {key: i, index: i, style: null}
                              return this.rowRenderer(props);
                            })}
                          </InfiniteScroll>
                        </div>
                      )}
                </div>
              </div>
            </div>
          );
        }
      } else {
        conditionalComponent = <div id="loading">Cargando...</div>;
      }
    }

    return (
      <React.Fragment>
        <PopUpConfirmationAdd
          title={'Agrega a tus amigos en pickwin'}
          label={`Quieres agregar a ${
            this.state.userSelected ? this.state.userSelected.user.nick : 'pickwinero'
            } como amigo`}
          submitting={this.state.submittingInvite}
          inviteStatus={this.state.inviteStatus}
          show={this.state.showAddConfirmation}
          handleInvite={this.followUser}
          hideConfirmation={this.hideAddConfirmation}
          userSelected={this.state.userSelected}
        />
        {conditionalComponent}
      </React.Fragment>
    );
  }
}

export default TablaPosiciones;
export { ISRTooltip };
