import React from 'react';
import {
  Button,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Modal,
  Panel,
  ControlLabel,
  FormControl,
} from 'react-bootstrap/lib';
import * as Sentry from '@sentry/browser';
import API from '../../utils/api';
import AddCardOpenPay from '../AddCardOpenPay';
import PrepagoForm from '../PrepagoForm';
import StorePayments from '../StorePayments';
import NETELLER from '../../assets/images/newPaymentIcons/NETELLER.png';
import TODITO from '../../assets/images/newPaymentIcons/TODITO.png';
import Utils from '../../utils/utils';
import numeral from 'numeral';
import utils from '../../utils/utils';
import Loading from '../Loading/Loading';
import SubscriptionRxs from '../../utils/subscriptions';
import SpeiPaymentForm from '../SpeiPaymentForm/';
import SpeiSTPPaymentForm from '../SpeiSTPPaymentForm/';

import './formDeposito.scss';
import styled from 'styled-components';
import PurchaseBanner from './PurchaseBanner';
import CryptoPaymentForm from '../CryptoPaymentForm';

// let OPEN_PAY_ID,
//   OPEN_PAY_API,
//   IS_SANDBOX = null;

// console.log('ENV DEPOSIT', process.env , process);

// if (process.env.NODE_ENV !== 'production') {
//   OPEN_PAY_API = process.env.OPEN_PAY_API_KEY;
//   OPEN_PAY_ID = process.env.OPEN_PAY_ID;
//   IS_SANDBOX = false;
// } else {
//   OPEN_PAY_API = process.env.OPEN_PAY_API_KEY;
//   OPEN_PAY_ID = process.env.OPEN_PAY_ID;
//   IS_SANDBOX = true;
// }

let closeFrameResponse = false;

const MESSAGES = [
  'Si tu tarjeta es BBVA, favor de depositar con la app BBVA Wallet',
  'Si tu tarjeta es Banorte, favor de depositar con la app Banorte Movil',
  'Si tu tarjeta es crédito Banamex favor de utilizar su app CitiBanamex'
]

/**
 * This event listens to the message
 * thas is sent by the responseIframe
 *
 */
window.addEventListener(
  'message',
  (e) => {
    if (e.data === 'orderSuccess') {
      // this.ngxFB.eventTrack('Purchase', {'currency': 'MXN', 'value': 50});
      SubscriptionRxs.announceClosedResponseIframeUpdated(true);
    }
    if (e.data === 'orderProcessing') {
      SubscriptionRxs.announceClosedResponseIframeUpdated(false);
    }

    if (e.data && e.data === 'closeFrameClicked') {
      // console.log('closeFrameClicked ss');
      closeFrameResponse = true;
      SubscriptionRxs.announceClosedResponseIframeUpdated(closeFrameResponse);
    }
  },
  false
);

const DepositResponse = ({
  dispatch,
  showDepositResponse,
  closeDepositResponse,
  tipoDeposito,
  netellerLink,
  astroPayLink,
  depositStatusSuccess,
  nick,
  type,
  newUserBalance,
  errorMessage,
  updateUserInfo,
  cantidadDeposito,
  otraCantidad,
}) => {
  let depositType = null;
  switch (tipoDeposito) {
    case 'TODITO':
      depositType = 'ToditoCash';
      break;
    case 'NETELLER':
      depositType = 'Neteller';
      break;
    default:
      depositType = 'Astropay';
      break;
  }

  // if( astroPayLink !== null && tipoDeposito !== 'NETELLER' && tipoDeposito !== 'TODITO' ){
  //   window.location = astroPayLink;
  //   // return null;
  // }

  // astroPayLink = astroPayLink+'&iframe_view=1';

  // console.log('astroPayLink', astroPayLink);
  let montoDeposito = 0;
  // console.log('CantidadDeposito:',cantidadDeposito);

  switch (cantidadDeposito) {
    case 2:
      montoDeposito = '50.00';
      break;
    case 4:
      montoDeposito = '200.00';
      break;
    case 5:
      montoDeposito = '100.00';
      break;
    case 6:
      montoDeposito = '300.00';
      break;
    case 7:
      montoDeposito = '500.00';
      break;
    case 8:
      montoDeposito = '1,000.00';
      break;
    case 'other':
      montoDeposito = otraCantidad;
      break;
  }

  return (
    <Modal
      show={showDepositResponse}
      className="responseDepositModal absoluteModal"
      dialogClassName="responseDepositDialog"
      backdropClassName="responseDepositBackdrop"
    >
      <div className="transactionPanel">
        <div
          id="panelTitle"
          className={`panel-abonar-titulo ${
            depositStatusSuccess !== null && !depositStatusSuccess ? 'failed' : ''
            }`}
        >
          {depositType !== 'ToditoCash' && astroPayLink !== null ? (
            <span>Abonar</span>
          ) : depositStatusSuccess ? (
            <span>! Felicidades {nick} , tú depósito fue exitoso!</span>
          ) : (
                <span>{nick} tenemos un problema.</span>
              )}
          <button className="cerrar-panel" onClick={closeDepositResponse}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty"></i>
            </div>
          </button>
        </div>
        {netellerLink !== null && tipoDeposito === 'NETELLER' ? (
          <div className="loading-background">
            <div className="message-deposit-response col-xs-10 col-xs-offset-1">
              <div className="text-centered flex-container column transaction-success">
                <span className="text-container flex-col col-center text-center">
                  Monto a depositar:{' '}
                  <span>
                    ${montoDeposito}
                    <small>MXN</small>
                  </span>
                </span>
                <span className="text-container flex-col col-center text-center">
                  <span>Estatus : </span>
                  <span>Pendiente</span>
                </span>
                <a href={netellerLink.url} target="_blank" rel="noopener noreferrer">
                  <div className="btn-wrap flex-col col-80 col-offset-10">
                    <Button className="btn btn-registrate btn-success">
                      <span> Abrir tu recibo </span>
                    </Button>
                  </div>
                </a>
              </div>
            </div>
            <iframe
              width="100%"
              height="700"
              id="external-payment-iframe"
              src={`${netellerLink.url}`}
              title="1"
            >
              <Loading label="..." />
            </iframe>
          </div>
        ) : null}
        {astroPayLink !== null && tipoDeposito !== 'NETELLER' && tipoDeposito !== 'TODITO' ? (
          <div className="loading-background">
            {tipoDeposito === 'VI' ? null : (
              <div className="message-deposit-response col-xs-12 ">
                <div className="text-centered flex-container column transaction-success">
                  <span className="text-container flex-col col-center text-center">
                    Monto a depositar:{' '}
                    <span>
                      ${montoDeposito}
                      <small>MXN</small>
                    </span>
                  </span>
                  <span className="text-container flex-col col-center text-center">
                    <span>Estatus : </span>
                    <span>Pendiente</span>
                  </span>
                  <a href={astroPayLink} target="_blank" rel="noopener noreferrer">
                    <div className="btn-wrap flex-col col-80 col-offset-10">
                      <Button className="btn btn-registrate btn-success">
                        <span> Abrir tú recibo </span>
                      </Button>
                    </div>
                  </a>
                </div>
              </div>
            )}
            <iframe
              width="100%"
              height="700"
              id="external-payment-iframe"
              className="loading-background"
              src={`${astroPayLink}`}
              title="2"
            >
              <Loading label="..." />
            </iframe>
          </div>
        ) : null}
        {astroPayLink === null && tipoDeposito !== 'NETELLER' && tipoDeposito !== 'TODITO' ? (
          <div className="loading-background">
            {
              <div className="message-deposit-response col-xs-10 col-xs-offset-1">
                <div className="text-centered flex-container transaction-success">
                  <i className="ion-close-circled icon-failed"></i>
                  <strong>Error:</strong>
                  <span className="text-container">{errorMessage}</span>
                </div>
              </div>
            }
          </div>
        ) : null}
        {tipoDeposito === 'TODITO' ? (
          <ToditoResponseStatus
            status={depositStatusSuccess}
            nick={nick}
            type={type}
            newUserBalance={newUserBalance}
            errorMessage={errorMessage}
            updateUserInfo={updateUserInfo}
            dispatch={dispatch}
          />
        ) : null}
      </div>
    </Modal>
  );
};

/**
 * This component handles the type of the deposit
 * bby a Toggle button Groupe and his values
 * @param banksPayments is teh array of type of payments
 * @param tipoDeposito is the current type of deposit
 * @param handleTipoDeposito is the function that handles the value of the toggle
 * @return Component
 */
const TypeDeposit = ({
  resetFormAmount,
  tipoDeposito,
  errorOtraCantidad,
  addNewCard,
  blockUi,
  handleTipoDeposito,
  handleDebitCard,
  handleDebitCardOpenPay,
  handleTutorialStep,
  handlePrepaidCard,
  handleStorePayment,
  handleSpeiCard,
  storePaymentState,
  speiPaymentState,
  feeniciaCardsList,
  deviceSession,
  openPayCardsList,
  amount,
  other_amount,
  debitCardState,
  debitCardStateOpenPay,
  itNeedsVerification,
  handleNewUserWithDeposit,
  dispatch,
  updateUserInfo,
  updateCardList,
  updateUserBalance,
  prepaidCardState,
}) => {
  // console.log(paymentList);
  return (
    <div className="control-tipo-de-pago ">
      <div className="flex-row ">
        <ControlLabel> ¿Cómo quieres hacer tu depósito?</ControlLabel>
      </div>
      {/* <Button active={debitCardState ? true : false }  className='type-deposit-btn btn text-center' onClick={handleDebitCard}>
          <span>Tarjeta de débito ó crédito</span>
        </Button>
        <Panel id="debit-credit-card" expanded={debitCardState}>
          {
            debitCardState
            ?
            <div className='flex-col col-no-padding'>
              <div className='flex-row row-no-padding help-note'>
                <i className='ion-alert-circled'></i>
                <h5 className='text-center'>¡Recuerda!, La tarjeta que uses para depositar, será la tarjeta a la que abonaremos tus fondos cuando solicites un retiro. en los casos que se apliquen.</h5>
              </div>
              <AddCardPanel
                handleNewUserWithDeposit={ handleNewUserWithDeposit }
                handleTutorialStep={handleTutorialStep }
                errorOtraCantidad={ errorOtraCantidad }
                addNewCard={ addNewCard }
                itNeedsVerification={ itNeedsVerification }
                resetFormAmount={ resetFormAmount }
                blockUi={ blockUi }
                amount={ amount}
                other_amount={ other_amount }
                feeniciaCardsList={ feeniciaCardsList }
                show={ true }
                dispatch={ dispatch }
                updateUserInfo={ updateUserInfo }
                updateUserBalance={ updateUserBalance }
              />
            </div>
            :
            null
          }
        </Panel> */}
      <Button
        active={debitCardStateOpenPay ? true : false}
        className="type-deposit-btn btn text-center"
        onClick={handleDebitCardOpenPay}
      >
        <span>Tarjeta de débito ó crédito</span>
      </Button>
      <Panel id="debit-credit-card" expanded={debitCardState}>
        {debitCardStateOpenPay ? (
          <div className="flex-col col-no-padding">
            <div className="flex-row row-no-padding help-note">
              <i className="ion-alert-circled"></i>
              <h5 className="text-center">
                ¡Recuerda!, La tarjeta que uses para depositar, será la tarjeta a la que abonaremos
                tus fondos cuando solicites un retiro. en los casos que se apliquen.
              </h5>
            </div>
            <AddCardOpenPay
              handleNewUserWithDeposit={handleNewUserWithDeposit}
              handleDebitCardOpenPay={handleDebitCardOpenPay}
              handleTutorialStep={handleTutorialStep}
              deviceSession={deviceSession}
              errorOtraCantidad={errorOtraCantidad}
              addNewCard={addNewCard}
              itNeedsVerification={itNeedsVerification}
              resetFormAmount={resetFormAmount}
              blockUi={blockUi}
              amount={amount}
              other_amount={other_amount}
              cardsListOpenPay={openPayCardsList}
              show={true}
              dispatch={dispatch}
              updateCardList={updateCardList}
              updateUserInfo={updateUserInfo}
              updateUserBalance={updateUserBalance}
            />
          </div>
        ) : null}
      </Panel>
      <Button
        className="type-deposit-btn btn text-center"
        active={storePaymentState ? true : false}
        onClick={handleStorePayment}
      >
        <span>Pago en tienda de conveniencia</span>
      </Button>
      <Panel id="store-payment" expanded={storePaymentState}>
        {storePaymentState ? (
          <StorePayments
            errorOtraCantidad={errorOtraCantidad}
            show={true}
            blockUi={blockUi}
            depositType={tipoDeposito}
            dispatch={dispatch}
            amount={amount}
            other_amount={other_amount}
            updateUserInfo={updateUserInfo}
            updateUserBalance={updateUserBalance}
          />
        ) : null}
      </Panel>
      <Button
        className="type-deposit-btn btn text-center"
        active={speiPaymentState ? true : false}
        onClick={handleSpeiCard}
      >
        <span>Pago con SPEI</span>
      </Button>
      <Panel id="store-payment" expanded={storePaymentState}>
        {speiPaymentState ? (
          <SpeiPaymentForm
            errorOtraCantidad={errorOtraCantidad}
            show={true}
            blockUi={blockUi}
            depositType={tipoDeposito}
            dispatch={dispatch}
            amount={amount}
            other_amount={other_amount}
            updateUserInfo={updateUserInfo}
            updateUserBalance={updateUserBalance}
          />
        ) : null}
      </Panel>
      <Button
        className="type-deposit-btn btn text-center"
        active={prepaidCardState ? true : false}
        onClick={handlePrepaidCard}
      >
        <span>Prepago</span>
      </Button>
      <Panel id="prepaidCard" expanded={prepaidCardState}>
        {prepaidCardState ? (
          <React.Fragment>
            <ButtonToolbar>
              <ToggleButtonGroup
                id="prepaid-card"
                className="col-xs-12 abonar"
                type="radio"
                value={tipoDeposito}
                name="tipoDeposito"
                onChange={handleTipoDeposito}
              >
                <ToggleButton
                  value="NETELLER"
                  className=" col-xs-6 col-md-4 col-hd-3 btn-formulario tipo-pago"
                >
                  <img src={NETELLER} className="paymentLogo" />
                </ToggleButton>
                <ToggleButton
                  value="TODITO"
                  className=" col-xs-6 col-md-4 col-hd-3 btn-formulario tipo-pago"
                >
                  <img src={TODITO} className="paymentLogo" />
                </ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
            <PrepagoForm
              handleNewUserWithDeposit={handleNewUserWithDeposit}
              errorOtraCantidad={errorOtraCantidad}
              show={true}
              blockUi={blockUi}
              depositType={tipoDeposito}
              dispatch={dispatch}
              amount={amount}
              other_amount={other_amount}
              updateUserInfo={updateUserInfo}
              updateUserBalance={updateUserBalance}
            />
          </React.Fragment>
        ) : null}
      </Panel>
    </div>
  );
};

/**
 * This component handle the response received by Todito Server API
 * It shows the status of the response
 */
const ToditoResponseStatus = ({
  status,
  nick,
  type,
  newUserBalance,
  errorMessage,
  updateUserInfo,
  dispatch,
}) => {
  // let userData = Utils.getUserInfo();
  // userData.balanceReal = newUserBalance;
  // utils.setUserInfo(userData);
  // updateUserInfo(userData);
  return (
    <div className="toditoPanelResponse">
      {status ? (
        <span className="icon-success">
          <i className="ion-checkmark-circled"></i>
        </span>
      ) : (
          <span className="icon-failed">
            <i className="ion-close-circled"></i>
          </span>
        )}
      <div className="col-xs-8 col-md-4 col-md-offset-4 col-xs-offset-2  text-centered flex-container align justify transaction-success">
        {status ? (
          <span>{nick} tu balance actual es: </span>
        ) : (
            <span>{nick} tenemos un problema con tu tarjeta</span>
          )}
      </div>
      <div className="col-xs-8 col-md-4 col-md-offset-4 col-xs-offset-2 text-centered flex-container align justify transaction-success">
        {status ? (
          <span className="text-container">
            <span style={{ fontSize: '1.5em' }}>
              ${numeral(newUserBalance / 100).format('0,000.00')}
              <small>MXN</small>
            </span>
          </span>
        ) : (
            <div className="flex-container column text-center align justify">
              <strong>Error:</strong>
              <span>{errorMessage}</span>
            </div>
          )}
      </div>
    </div>
  );
};

/**
 * This function Handles and updates the amount that wanna purchase the user
 * @param cantidadDeposito The current Value of the amount
 * @param handleDepositoCantidad this is the function that updates the amount
 * @param otraCantidad this is the calue if the otherqunty is active
 * @param handleOtraCantidad this function updates the value if Other Qnty is active
 */
const CantidadDeposito = ({
  cantidadDeposito,
  handleDepositoCantidad,
  otraCantidad,
  handleOtraCantidad,
  errorMessage,
  errorOtraCantidad,
}) => {
  return (
    <div id="cantidad-deposito">
      <ControlLabel>¿Cuánto quieres abonar?</ControlLabel>
      <ButtonToolbar className="cantidad-abonar-toolbar">
        <ToggleButtonGroup
          type="radio"
          name="cantidadDeposito"
          value={cantidadDeposito}
          onChange={handleDepositoCantidad}
        >
          <ToggleButton value={2} className="btn-formulario">
            $ 50{' '}
          </ToggleButton>
          <ToggleButton value={5} className="btn-formulario abonar">
            $ 100{' '}
          </ToggleButton>
          {/* <ToggleButton value={6} className="btn-formulario abonar"><img src={billete}/>$ 300 MXN</ToggleButton> */}
          <ToggleButton value={4} className="btn-formulario abonar">
            $ 200{' '}
          </ToggleButton>
          <ToggleButton value={7} className="btn-formulario abonar">
            $ 500{' '}
          </ToggleButton>
          {/* <ToggleButton value={8} className="btn-formulario abonar"><img src={billete}/>$ 1,000 MXN</ToggleButton> */}
          <ToggleButton value={'other'} className="btn-formulario abonar">
            Otra Cantidad
          </ToggleButton>
        </ToggleButtonGroup>
      </ButtonToolbar>
      {/* {cantidadDeposito === 'other' ? (
        <div className="flex-col col-no-padding">
          <ControlLabel>Ingresa una cantidad</ControlLabel>
          <FormControl
            required
            name={'other_amount'}
            type="number"
            value={otraCantidad}
            inputMode="numeric"
            pattern="[0-9]*"
            max={50000}
            min={50}
            onChange={handleOtraCantidad}
          ></FormControl>
          {errorOtraCantidad ? <span className="error-formulario">{errorMessage}</span> : null}
        </div>
      ) : null} */}
    </div>
  );
};

class FormDepositoFeenicia extends React.Component {
  constructor(props) {
    super(props);
    this.userData = Utils.isLogged() ? Utils.getUserInfo() : null;
    if (!this.userData) {
      throw 'Error userData not exist';
    }
    // this.userData.user.personal_id_number = null;
    this.paymentData = {
      amount: 6,
      other_amount: null,
      device_session_id: null,
      astropayData: {
        bank: null,
        cpf: null,
        payment_type: null,
      },
      toditoCashData: {
        card_number: null,
        nip: null,
      },
    };

    this.state = {
      activeIndex: 0,
      submittingSave: false,
      cardData: {
        card_number: null,
        card_name: null,
        expiration_year: null,
        expiration_month: null,
        security_code: null,
      },
      amount: 'other',
      other_amount: 200,
      feeniciaCardsList: [],
      isCardDataReady: false,
      banksPayments: null,
      errorMessage: null,
      mounted: false,
      netellerLink: null,
      newUserBalance: null,
      canSubmit: false,
      cantidadDeposito: 4,
      closeFrameResponse: closeFrameResponse,
      depositAmount: null,
      idNumber: '',
      motherLastName: null,
      nameUser: null,
      otraCantidad: null,
      paymentType: '03',
      requesError: false,
      requestSuccess: false,
      showDebitCreditCard: false,
      showDebitCreditCardOpenPay: true,
      showModal: false,
      showPrepaidCard: false,
      showStorePayment: false,
      showTransferPayment: false,
      submitting: false,
      tipoDeposito: 'VI',
      toditoCardNumber: '',
      toditoNIP: '',
      errorOtraCantidad: false,
      errorWithOpenPay: false,
      cashOperation: 'card',
    };

    this.resetFormAmount = () => {
      this.setState({
        amount: 'other',
        other_amount: 200,
        tipoDeposito: 'VI',
      });
    };

    this.handleSubmittingSave = () => {
      this.setState({ submittingSave: !this.state.submittingSave });
    };

    this.updateCardListOpenPay = () => {
      API.fetchOpenPayCards().then((response) => {
        this.initializeFormOpenPay(response.data.cards).then((responseInitializedOpenPay) => {
          this.setState(responseInitializedOpenPay);
        });
      });
    };

    this.convertToPesos = (cant, otraCantidad) => {
      switch (cant) {
        case 2:
          return '$50.00';
          break;
        case 4:
          return '$200.00';
          break;
        case 5:
          return '$100.00';
          break;
        case 7:
          return '$500.00';
          break;
        case 'other':
          return `$${otraCantidad}.00`;
          break;
      }
    };

    this.checkIfNeedsVerification = (amount) => {
      let userData = utils.getUserInfo();

      if (userData.is_new && amount >= 3000) {
        return true;
      } else if (!userData.identity_verified && amount > 20000) {
        return true;
      }
      return false;
    };
    /*********************************************
     * Methods and handlers used in this component
     *********************************************/

    this.handleSpeiCard = () => {
      console.log('speiPAy');
      this.setState((prevState) => {
        return {
          showSpeiDeposit: !prevState.showSpeiDeposit,
          showPrepaidCard: false,
          showDebitCreditCard: false,
          showDebitCreditCardOpenPay: false,
          showStorePayment: false,
          showTransferPayment: false,
        };
      });
    };
    this.handlePrepaidCard = () => {
      console.log('Prepaid');
      this.setState((prevState) => {
        return {
          showPrepaidCard: !prevState.showPrepaidCard,
          showSpeiDeposit: false,
          showDebitCreditCard: false,
          showStorePayment: false,
          showDebitCreditCardOpenPay: false,
          showTransferPayment: false,
          tipoDeposito: !prevState.showPrepaidCard ? 'TODITO' : null,
        };
      });
    };

    this.handleDebitCard = () => {
      console.log('HandleDebitCard');
      this.setState((prevState) => {
        return {
          showDebitCreditCard: !prevState.showDebitCreditCard,
          showDebitCreditCardOpenPay: false,
          showPrepaidCard: false,
          showSpeiDeposit: false,
          showStorePayment: false,
        };
      });
    };
    this.handleDebitCardOpenPay = () => {
      console.log('handleDebitCardOpenPay');
      this.setState((prevState) => {
        return {
          showDebitCreditCardOpenPay: !prevState.showDebitCreditCardOpenPay,
          showDebitCreditCard: null,
          showPrepaidCard: false,
          showSpeiDeposit: false,
          showStorePayment: false,
        };
      });
    };
    this.handleStorePayment = () => {
      console.log('Store');
      this.setState((prevState) => {
        return {
          showStorePayment: !prevState.showStorePayment,
          showDebitCreditCardOpenPay: false,
          showPrepaidCard: false,
          showSpeiDeposit: false,
          showDebitCreditCard: false,
        };
      });
    };

    this.disableButton = () => {
      this.setState({ canSubmit: false });
    };
    this.enableButton = () => {
      this.setState({ canSubmit: true });
    };
    this.resetData = () => {
      this.setState({ requestSuccess: false, requesError: false });
    };
    this.submitFormDeposit = (e) => {
      console.log('Submit Form Deposit');
    };
    this.showModal = () => {
      this.setState({ showModal: true });
    };
    this.closeModal = () => {
      this.setState(
        {
          showModal: false,
          requestError: false,
          requestSuccess: false,
          idNumber: '',
          paymentType: null,
          tipoDeposito: null,
          otraCantidad: null,
          errorMessage: null,
          toditoCardNumber: '',
          toditoNIP: '',
          newUserBalance: null,
        },
        () => {
          if (this.props.dispatch) {
            this.props.updateUserInfo(this.props.dispatch);
          } else {
            this.props.updateUserInfo();
          }
        }
      );
    };

    this.handleCardStatus = (status, cardData) => {
      console.log('Card Data is Ready ', status);
      this.setState({ isCardDataReady: status, cardData: cardData });
    };

    this.handleDepositoCantidad = (eventKey) => {
      this.setState({
        other_amount: eventKey === 'other' ? 300 : null,
        amount: eventKey,
      });
    };

    this.handleOtraCantidad = (e) => {
      let itNeedsVerification = this.checkIfNeedsVerification(e.target.value);

      let error = false;

      if (Number(e.target.value) < 50 || Number(e.target.value) > 50000) {
        error = true;
      }

      this.setState({
        other_amount: e.target.value.replace(/[e,E]/, ''),
        itNeedsVerification,
        errorOtraCantidad: error,
      });
    };

    this.handleTipoDeposito = (eventKey) => {
      // let bankObject = this.state.banksPayments.banks.find( bank => bank.code === eventKey );
      console.log(eventKey);
      this.setState({
        tipoDeposito: eventKey,
      });
    };

    this.initializeFormFeenicia = (feeniciaCardsList) => {
      console.log('Initialize Form');
      console.log('feeniciaCardsList', feeniciaCardsList);
      return new Promise((resolve, reject) => {
        let hasSavedCardsFeenicia = feeniciaCardsList && feeniciaCardsList.length > 0;
        console.log('hasSaved Cards', hasSavedCardsFeenicia);
        let initializedState = {
          feeniciaCardsList: feeniciaCardsList,
          hasSavedCardsFeenicia: hasSavedCardsFeenicia,
          mounted: true,
        };
        if (feeniciaCardsList.length > 0) {
          //  initializedState['showDebitCreditCard'] = false;
          resolve(initializedState);
        } else {
          resolve(initializedState);
        }
      });
    };

    this.initializeFormOpenPay = (openPayCardsList) => {
      console.log('Initialize Form');
      console.log('openPayCardsList', openPayCardsList);
      return new Promise((resolve, reject) => {
        let hasSavedCardsOpenPay = openPayCardsList && openPayCardsList.length > 0;
        console.log('hasSaved Cards', hasSavedCardsOpenPay);
        let initializedState = {
          openPayCardsList: openPayCardsList,
          hasSavedCardsOpenPay: hasSavedCardsOpenPay,
          mounted: true,
        };
        if (openPayCardsList.length > 0) {
          //  initializedState['showDebitCreditCard'] = false;
          resolve(initializedState);
        } else {
          resolve(initializedState);
        }
      });
    };

    this.handleSavedCards = (selectedCard) => {
      console.log('Selected saved Cards', selectedCard);
    };

    this.closeDepositResponse = () => {
      console.log('Close Deposit Response');
      this.setState(
        {
          showDepositResponse: false,
          netellerLink: null,
          depositAmount: null,
        },
        () => {
          if (this.props.updateUserInfo !== undefined && !this.state.errorMessage) {
            // if( this.props.dispatch && this.props.updateBalance ){
            //   this.props.updateBalance( this.props.dispatch,  );
            // }
            // else {
            // this.props.updateUserInfo( utils.getUserInfo() );
            // }
          }
        }
      );
    };

    this.addNewCard = (newCard) => {
      if (!newCard) {
        return;
      }
      console.log('Add New Card', newCard);
      let temporalCards = this.state.feeniciaCardsList;
      temporalCards = [...temporalCards, newCard];

      this.setState({ feeniciaCardsList: temporalCards });
    };

    this.cashOperationHandler = (operation) => {
      this.setState({cashOperation: operation});
    }

    this.addCashAmount = (operation) => {
      if (operation === 'add') {
        if (Number(this.state.other_amount) < 2500) {
          this.setState({other_amount: Number(this.state.other_amount) + 50});
        }
      } else {
        if (Number(this.state.other_amount) > 50) {
          this.setState({other_amount: Number(this.state.other_amount) - 50});
        }
      }
    }

    this.submitPayment = this.submitPayment.bind(this);

    this.handleDeleteCard = (cardId) => {
      return new Promise(resolve => {
        API.postOpenPayCardsDelete(cardId)
        .then((response) => {
          console.log(response);
          let newCardsList = this.state.openPayCardsList.filter(item => item.id !== cardId);
          this.setState({openPayCardsList: newCardsList});
          resolve({status: 'success', cardsLength: newCardsList.length});
        })
        .catch((err) => {
          console.error(err);
          resolve({status: 'error'});
        });
        //resolve('resolved');
      });
    }

    this.getSpeiGateway = async () => {
      this.setState({ loadingGateway: true })
      try {
        const { data } = await API.getSpeiGateway()

        if (data && data.gateway) {
          this.setState({
            speiGateway: data.gateway
          })
        }
      } catch (error) {
        Sentry.captureException(error)
      }
      this.setState({ loadingGateway: false })
    }

    this.showSPEIForm = () => {
      const { speiGateway } = this.state

      // Spei form depends for gateway
      if (speiGateway === 'stp') {
        this.cashOperationHandler('spei-stp')
      } else {
        this.cashOperationHandler('spei')
      }
    }
  }

  /**
   * This functon submit the payment info to the server
   */
  submitPayment(e) {
    this.setState({ submitting: true });
    console.log('SubmitPayment', this.state.submitting);
    switch (this.state.tipoDeposito) {
      case 'NETELLER':
        console.log('Subimt Payment Neteller!');
        /*********
         * Neteller
         * *******/
        this.paymentData.amount = this.state.cantidadDeposito;
        this.paymentData.other_amount =
          this.state.cantidadDeposito === 'other' ? this.state.otraCantidad : null;
        console.log(this.paymentData);
        API.postPurchaseNeteller(this.paymentData)
          .then((response) => {
            console.log(response);
            this.callbackNeteller(response.data);
          })
          .catch((e) => {
            console.error(e);
            Sentry.captureException(e);
          });
        break;
      case 'TODITO':
        console.log('Subimt Payment Todito cash!');
        /*********
         * Todito cash
         * *******/
        this.paymentData.amount = this.state.cantidadDeposito;
        this.paymentData.other_amount =
          this.state.cantidadDeposito === 'other' ? this.state.otraCantidad : null;
        this.paymentData.toditoCashData.card_number = this.state.toditoCardNumber;
        this.paymentData.toditoCashData.nip = this.state.toditoNIP;

        // console.log(this.paymentData);
        API.postPurchaseTodito(this.paymentData)
          .then((response) => {
            console.log(response);
            this.callbackTodito(response.data);
          })
          .catch((e) => {
            console.error(e);
            Sentry.captureException(e);
          });
        break;
      default:
        console.log('Subimt Payment Astropay !');
        console.log(this.state.userRfc);
        /************
         * Astropay
         * *********/
        this.paymentData.amount = this.state.cantidadDeposito;
        this.paymentData.other_amount =
          this.state.cantidadDeposito === 'other' ? this.state.otraCantidad : null;
        this.paymentData.astropayData.bank = this.state.tipoDeposito;
        this.paymentData.astropayData.cpf = this.state.userRfc;
        this.paymentData.astropayData.payment_type = this.state.paymentType;
        console.log(this.paymentData);
        if (
          this.paymentData.astropayData.amount === null ||
          this.paymentData.astropayData.amount === '' ||
          this.paymentData.astropayData.bank === null ||
          this.paymentData.astropayData.bank === '' ||
          this.paymentData.astropayData.cpf === null ||
          this.paymentData.astropayData.cpf === '' ||
          this.paymentData.astropayData.payment_type === null ||
          this.paymentData.astropayData.payment_type === ''
        ) {
          this.setState({
            submitting: false,
          });
          console.log('NOt complete');
          return;
        }
        API.postPurchaseAstroPay(this.paymentData)
          .then((response) => {
            console.log(response);
            this.callbackAstroPay(response.data);
          })
          .catch((e) => {
            console.error(e);
            Sentry.captureException(e);
          });
        break;
    }
  }

  purchaseSuccessCallback(data) {
    console.log('Purchase Callback', data);
  }

  componentDidMount() {
    if (Utils.isLogged()) {
      // let iframe = $('#external-payment-iframe');
      // console.log(iframe);
      this.closeDepositResponseSubscription = SubscriptionRxs.closeDepositResponseIframeReceived$.subscribe(
        (closeStataus) => {
          console.log('Changing the state throught the iframe');
          this.closeDepositResponse();
        }
      );
      console.log('isProduction:', process.env.NODE_ENV === 'production');
      console.log('isDev:', process.env.NODE_ENV === 'development');

      console.log('Initialize this Vars', process.env);

      if (process.env.NODE_ENV === 'production') {
        window.OpenPay.setId('myabfqccohuj4kszwr7y');
        window.OpenPay.setApiKey('pk_e78ceae59eaf42f68c2dba4cbd147265');
      } else {
        window.OpenPay.setSandboxMode(true);
        window.OpenPay.setId('mqfki2pbqpbve54kabor');
        window.OpenPay.setApiKey('pk_f548ab805b93403b91009278e32e3fd4');
      }

      try {
        this.paymentData.device_session_id = window.OpenPay.deviceData.setup();

        API.fetchOpenPayCards()
          .then((openPayCards) => {
            this.initializeFormOpenPay(openPayCards.data.cards).then((responseInitialized) => {
              console.log(responseInitialized);
              this.setState(responseInitialized);
            });
          })
          .catch((error) => {
            console.error(error);
            this.setState({ mounted: true, errorWithOpenPay: true });
          });
      } catch (error) {
        this.setState({ mounted: true, errorWithOpenPay: true });
      }

      this.getSpeiGateway()
    }



    // Change message
    this.tick = window.setInterval(() => {
      if (this.state.activeIndex < MESSAGES.length - 1) {
        this.setState(({ activeIndex }) => ({ activeIndex: activeIndex + 1 }))
      } else {
        this.setState({ activeIndex: 0 })
      }
    }, 4500)
  }
  componentDidUpdate(prevProps, prevState) {
    /**-
     * Handle Subscription to Closed Iframe
     */
    if (this.state.showDepositResponse) {
      // let iframe = $('#external-payment-iframe');
      //let iframe = document.getElementById('external-payment-iframe');
      // if(iframe === null){return');
      // iframe.contentWindow.addEventListener('loadstart', () => {console.log('LoadStarted'); })
      // iframe.contentWindow.addEventListener('loadend', () => {console.log('LoadEnd'); })
    } else if (prevState.showDepositResponse && !this.state.showDepositResponse) {
      // let iframe = document.getElementById('external-payment-iframe');
      // console.log('remove event to the iframe');
      // iframe.contentWindow.removeEventListener('loadstart')
      // iframe.contentWindow.removeEventListener('loadend')
    }
  }


  stopPropagation = (event) => {
    event.stopPropagation()
  }

  render() {
    // let isReady =
    //   this.state.cantidadDeposito !== null &&
    //   this.state.tipoDeposito !== null &&
    //   this.state.isCardDataReady;
   {/* Elige el monto en MXN que quieras abonar a tu cuenta de Pickwin */}

    let amountComponent = (
      <>
        <div className="casher-amount-container">
          <div className='cashier-button' onClick={() => this.addCashAmount('remove')}>-</div>
          <div>
            <FormControl
              required
              name={'other_amount'}
              type="number"
              value={this.state.other_amount}
              inputMode="numeric"
              pattern="[0-9]*"
              max={50000}
              min={50}
              onChange={this.handleOtraCantidad}
              style={{marginBottom: '0px', textAlign: 'center', height: '24px'}}
            ></FormControl>
          </div>
          <div className='cashier-button' onClick={() => this.addCashAmount('add')}>+</div>
        </div>
        <div className='flex-row'>
          {this.state.errorOtraCantidad ? <span className="error-formulario">Mínimo $50 MXN máximo $25,000 MXN</span> : null}
        </div>
        </>
    );

    const userData = Utils.getUserInfo();

    let nick = !userData ? '' : userData.nick;
    // console.log(userData.user);
    // let rfcExist =
    //   userData &&
    //   userData.personal_id_number !== undefined &&
    //   userData.personal_id_number !== null &&
    //   userData.personal_id_number.length <= 5;

    let conditionalComponent = null;
    if (this.state.mounted === false) {
      conditionalComponent = <Loading label="..." />;
    } else {
      conditionalComponent = (
        <div className="flex-col" style={{ maxWidth: 580, margin: '1em auto' }} onClick={this.stopPropagation}>
          <Modal
            show={this.state.submittingSave}
            className="responseDepositModal absoluteModal"
            dialogClassName="responseDepositDialog"
            backdropClassName="responseDepositBackdrop"
          >
            <div className="flex-container justify align column">
              <i className="ion-load-a loading"> </i>
              <div className="flex-row row-center">
                <div className="flex-col col-center text-center">
                  <div className="flex-col col-center">
                    <strong className="flex-justify align center">No cierres esta ventana,</strong>
                  </div>
                  <div className="flex-col col-center">
                    <strong className="flex-justify align center">
                      Estamos procesando tu petición...
                    </strong>
                  </div>
                  <div className="flex-col col-center">
                    <strong className="flex-justify align center">
                      Espera un momento porfavor...
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <DepositResponse
            dispatch={this.props.dispatch}
            showDepositResponse={this.state.showDepositResponse}
            closeDepositResponse={this.closeDepositResponse}
            tipoDeposito={this.state.tipoDeposito}
            netellerLink={this.state.netellerLink}
            astroPayLink={this.state.astroPayLink}
            depositStatusSuccess={this.state.depositStatusSuccess}
            nick={nick}
            type={this.props.type}
            newUserBalance={this.state.newUserBalance}
            errorMessage={this.state.errorMessage}
            updateUserInfo={this.props.updateUserInfo}
            cantidadDeposito={this.state.cantidadDeposito}
            otraCantidad={this.state.otraCantidad}
          />
          <div controlId="FormDepositoFeenicia">
            <PurchaseBanner />
            <div className='flex-row'>
              <ControlLabel className='mb-2'> Elige como quieres pagar</ControlLabel>
            </div>
            <div className='cashier-menu-container'>
              <div className={`menu-item-cashier ${this.state.cashOperation === 'card' && 'active'}`} onClick={() => this.cashOperationHandler('card')}>Tarjeta</div>
              <div className={`menu-item-cashier ${this.state.cashOperation === 'store' && 'active'}`} onClick={() => this.cashOperationHandler('store')}>Tienda</div>
              {/* <div className={`menu-item-cashier ${this.state.cashOperation === 'crypto' && 'active'}`} onClick={() => this.cashOperationHandler('crypto')}>Crypto</div> */}
              <div className={`menu-item-cashier ${this.state.cashOperation.includes('spei') && 'active'}`} onClick={this.showSPEIForm}>SPEI</div>
              <div className={`menu-item-cashier ${this.state.cashOperation === 'prepago' && 'active'}`} onClick={() => this.cashOperationHandler('prepago')}>Prepago</div>
            </div>

            <Box className='flex-col'>
              {this.state.cashOperation === 'card' && (
                <AddCardOpenPay
                  handleNewUserWithDeposit={this.props.handleNewUserWithDeposit}
                  handleDebitCardOpenPay={this.handleDebitCardOpenPay}
                  handleTutorialStep={this.props.handleTutorialStep}
                  deviceSession={this.paymentData.device_session_id}
                  errorOtraCantidad={this.state.errorOtraCantidad}
                  addNewCard={this.addNewCard}
                  itNeedsVerification={this.itNeedsVerification}
                  resetFormAmount={this.resetFormAmount}
                  blockUi={this.handleSubmittingSave}
                  amount={this.state.amount}
                  other_amount={this.state.other_amount}
                  cardsListOpenPay={this.state.openPayCardsList}
                  show={true}
                  dispatch={this.props.dispatch}
                  updateCardList={this.updateCardListOpenPay}
                  updateUserInfo={this.props.updateUserInfo}
                  updateUserBalance={this.props.updateUserBalance}
                  amountComponent={amountComponent}
                  handleDeleteCard = {this.handleDeleteCard}
                  activeMessage={MESSAGES[this.state.activeIndex]}
                />
              )}

              {this.state.cashOperation === 'store' && (
                <StorePayments
                  errorOtraCantidad={this.state.errorOtraCantidad}
                  show={true}
                  blockUi={this.handleSubmittingSave}
                  depositType={this.state.tipoDeposito}
                  dispatch={this.props.dispatch}
                  amount={this.state.amount}
                  other_amount={this.state.other_amount}
                  updateUserInfo={this.props.updateUserInfo}
                  updateUserBalance={this.props.updateUserBalance}
                  amountComponent={amountComponent}
                />
              )}

              {/* {this.state.cashOperation === 'crypto' && (
                <CryptoPaymentForm
                  errorOtraCantidad={this.state.errorOtraCantidad}
                  show={true}
                  blockUi={this.handleSubmittingSave}
                  depositType={this.state.tipoDeposito}
                  dispatch={this.props.dispatch}
                  amount={this.state.amount}
                  other_amount={this.state.other_amount}
                  updateUserInfo={this.props.updateUserInfo}
                  updateUserBalance={this.props.updateUserBalance}
                  amountComponent={amountComponent}
                />
              )} */}

              {this.state.cashOperation === 'spei' && (
                <SpeiPaymentForm
                  errorOtraCantidad={this.state.errorOtraCantidad}
                  show={true}
                  blockUi={this.handleSubmittingSave}
                  depositType={this.state.tipoDeposito}
                  dispatch={this.props.dispatch}
                  amount={this.state.amount}
                  other_amount={this.state.other_amount}
                  updateUserInfo={this.props.updateUserInfo}
                  updateUserBalance={this.props.updateUserBalance}
                  amountComponent={amountComponent}
                />
              )}

              {this.state.cashOperation === 'spei-stp' && (
                <SpeiSTPPaymentForm
                  errorOtraCantidad={this.state.errorOtraCantidad}
                  show={true}
                  blockUi={this.handleSubmittingSave}
                  depositType={this.state.tipoDeposito}
                  dispatch={this.props.dispatch}
                  amount={this.state.amount}
                  other_amount={this.state.other_amount}
                  updateUserInfo={this.props.updateUserInfo}
                  updateUserBalance={this.props.updateUserBalance}
                  amountComponent={amountComponent}
                />
              )}

              {this.state.cashOperation === 'prepago' && (
                <Panel id="prepaidCard" expanded={this.state.showPrepaidCard} className='mt-1 mb-2'>
                  <div className='flex-container align'>
                    <div className='col-70'>
                      <ButtonToolbar>
                        <ToggleButtonGroup
                          id="prepaid-card"
                          className="col-xs-12 abonar"
                          type="radio"
                          value={this.state.tipoDeposito}
                          name="tipoDeposito"
                          onChange={this.handleTipoDeposito}
                        >
                          {/* <ToggleButton
                            value="NETELLER"
                            className=" col-xs-6 col-md-4 col-hd-3 btn-formulario tipo-pago"
                          >
                            <img src={NETELLER} className="paymentLogo" />
                          </ToggleButton> */}
                          <ToggleButton
                            value="TODITO"
                            className=" col-xs-6 col-md-4 col-hd-3 btn-formulario tipo-pago"
                          >
                            <img src={TODITO} className="paymentLogo" />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </ButtonToolbar>
                    </div>
                    <div className='col-30'>{amountComponent}</div>
                  </div>
                  <PrepagoForm
                    handleNewUserWithDeposit={this.props.handleNewUserWithDeposit}
                    errorOtraCantidad={this.state.errorOtraCantidad}
                    show={true}
                    blockUi={this.handleSubmittingSave}
                    depositType={this.state.tipoDeposito}
                    dispatch={this.props.dispatch}
                    amount={this.state.amount}
                    other_amount={this.state.other_amount}
                    updateUserInfo={this.props.updateUserInfo}
                    updateUserBalance={this.props.updateUserBalance}
                  />
                </Panel>
              )}
            </Box>
          </div>
        </div>
      );
    }
    return conditionalComponent;
  }
}

export default FormDepositoFeenicia;


const Box = styled.div`
  input, select {
    background: transparent;
    color: #FFF;
    border: 1px solid gray !important;
    border-radius: 0.5em !important;
  }

  input[name='other_amount'] {
    border-radius: 0em !important;
  }

  option {
    background: #000000;
    color: #FFF;
  }
`
